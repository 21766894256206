/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { EventEmitter, Injectable, Directive, Optional, TemplateRef, forwardRef, isDevMode, HostBinding, ViewChild, ViewContainerRef, Input, Output, ContentChild, Component, ChangeDetectionStrategy, ElementRef, NgZone, Renderer2, ChangeDetectorRef, ComponentFactoryResolver, Host, NgModule } from '@angular/core';
import { isDocumentAvailable, Keys, hasObservers, isChanged, anyChanged, guid } from '@progress/kendo-angular-common';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { Subject, of, BehaviorSubject, Subscription, EMPTY, merge } from 'rxjs';
import { validatePackage } from '@progress/kendo-licensing';
import { trigger, transition, style, animate } from '@angular/animations';
import { getter, setter } from '@progress/kendo-common';
import { filter, catchError, tap, finalize, switchMap, take, map, delay, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import Draggable from '@telerik/kendo-draggable';
import { InputsModule } from '@progress/kendo-angular-inputs';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-treeview',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1617882976,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * @hidden
 */
class DataChangeNotificationService {
    constructor() {
        this.changes = new EventEmitter();
    }
    notify() {
        this.changes.emit();
    }
}

/**
 * @hidden
 */
const hasChildren = () => false;
/**
 * @hidden
 */
const isChecked = () => 'none';
/**
 * @hidden
 */
const isDisabled = () => false;
/**
 * @hidden
 */
const isExpanded = () => true;
/**
 * @hidden
 */
const isSelected = () => false;
/**
 * @hidden
 */
const isVisible = () => true;
/**
 * @hidden
 */
const trackBy = (_, item) => item;

/**
 * @hidden
 */
let ExpandStateService = class ExpandStateService {
    /**
     * @hidden
     */
    constructor() {
        this.changes = new Subject();
    }
    expand(index, dataItem) {
        this.changes.next({ dataItem, index, expand: true });
    }
    collapse(index, dataItem) {
        this.changes.next({ dataItem, index, expand: false });
    }
};
ExpandStateService = __decorate([
    Injectable()
], ExpandStateService);

/**
 * @hidden
 */
let IndexBuilderService = class IndexBuilderService {
    /**
     * @hidden
     */
    constructor() {
        this.INDEX_SEPARATOR = '_';
    }
    nodeIndex(index = '', parentIndex = '') {
        return `${parentIndex}${parentIndex ? this.INDEX_SEPARATOR : ''}${index}`;
    }
    indexForLevel(index, level) {
        return index.split(this.INDEX_SEPARATOR).slice(0, level).join(this.INDEX_SEPARATOR);
    }
    lastLevelIndex(index = '') {
        const parts = index.split(this.INDEX_SEPARATOR);
        if (!parts.length) {
            return NaN;
        }
        return parseInt(parts[parts.length - 1], 10);
    }
    level(index) {
        return index.split(this.INDEX_SEPARATOR).length;
    }
};
IndexBuilderService = __decorate([
    Injectable()
], IndexBuilderService);

/**
 * @hidden
 */
let LoadingNotificationService = class LoadingNotificationService {
    /**
     * @hidden
     */
    constructor() {
        this.changes = new Subject();
    }
    notifyLoaded(index) {
        this.changes.next(index);
    }
};
LoadingNotificationService = __decorate([
    Injectable()
], LoadingNotificationService);

const focusableRegex = /^(?:a|input|select|option|textarea|button|object)$/i;
/**
 * @hidden
 */
const match = (element, selector) => {
    const matcher = element.matches || element.msMatchesSelector || element.webkitMatchesSelector;
    if (!matcher) {
        return false;
    }
    return matcher.call(element, selector);
};
/**
 * @hidden
 */
const closestWithMatch = (element, selector) => {
    if (!document.documentElement.contains(element)) {
        return null;
    }
    let parent = element;
    while (parent !== null && parent.nodeType === 1) {
        if (match(parent, selector)) {
            return parent;
        }
        parent = parent.parentElement || parent.parentNode;
    }
    return null;
};
/**
 * @hidden
 */
const noop = () => { };
/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
const isBlank = (value) => value === null || value === undefined;
/**
 * @hidden
 */
const isArray = (value) => Array.isArray(value);
/**
 * @hidden
 */
const isNullOrEmptyString = (value) => isBlank(value) || value.trim().length === 0;
/**
 * @hidden
 */
const closestNode = (element) => {
    const selector = 'li.k-treeview-item';
    if (!isDocumentAvailable()) {
        return null;
    }
    if (element.closest) {
        return element.closest(selector);
    }
    else {
        return closestWithMatch(element, selector);
    }
};
/**
 * @hidden
 */
const isFocusable = (element) => {
    if (element.tagName) {
        const tagName = element.tagName.toLowerCase();
        const tabIndex = element.getAttribute('tabIndex');
        const skipTab = tabIndex === '-1';
        let focusable = tabIndex !== null && !skipTab;
        if (focusableRegex.test(tagName)) {
            focusable = !element.disabled && !skipTab;
        }
        return focusable;
    }
    return false;
};
/**
 * @hidden
 */
const isContent = (element) => {
    const scopeSelector = '.k-in:not(.k-treeview-load-more-button),.k-treeview-item,.k-treeview';
    if (!isDocumentAvailable()) {
        return null;
    }
    let node = element;
    while (node && !match(node, scopeSelector)) {
        node = node.parentNode;
    }
    if (node) {
        return match(node, '.k-in:not(.k-treeview-load-more-button)');
    }
};
/**
 * @hidden
 *
 * Returns the nested .k-in:not(.k-treeview-load-more-button) element.
 * If the passed parent item is itself a content node, it is returned.
 */
const getContentElement = (parent) => {
    if (!isPresent(parent)) {
        return null;
    }
    const selector = '.k-in:not(.k-treeview-load-more-button)';
    if (match(parent, selector)) {
        return parent;
    }
    return parent.querySelector(selector);
};
/**
 * @hidden
 */
const isLoadMoreButton = (element) => {
    return isPresent(closestWithMatch(element, '.k-in.k-treeview-load-more-button'));
};
/**
 * @hidden
 */
const closest = (node, predicate) => {
    while (node && !predicate(node)) {
        node = node.parentNode;
    }
    return node;
};
/**
 * @hidden
 */
const hasParent = (element, container) => {
    return Boolean(closest(element, (node) => node === container));
};
/**
 * @hidden
 */
const focusableNode = (element) => element.nativeElement.querySelector('li[tabindex="0"]');
/**
 * @hidden
 */
const nodeId = (node) => node ? node.getAttribute('data-treeindex') : '';
/**
 * @hidden
 */
const nodeIndex = (item) => (item || {}).index;
/**
 * @hidden
 */
const dataItemsEqual = (first, second) => {
    if (!isPresent(first) && !isPresent(second)) {
        return true;
    }
    return isPresent(first) && isPresent(second) && first.item.dataItem === second.item.dataItem;
};
/**
 * @hidden
 */
const getDataItem = (lookup) => {
    if (!isPresent(lookup)) {
        return lookup;
    }
    return lookup.item.dataItem;
};
/**
 * @hidden
 */
const isArrayWithAtLeastOneItem = v => v && Array.isArray(v) && v.length !== 0;
/**
 * @hidden
 * A recursive tree-filtering algorithm that returns:
 * - all child nodes of matching nodes
 * - a chain parent nodes from the match to the root node
 */
const filterTree = (items, term, { operator, ignoreCase }, textField, depth = 0) => {
    const field = typeof textField === "string" ? textField : textField[depth];
    return items.reduce((acc, wrapper) => {
        const matcher = typeof operator === "string" ? matchByFieldAndCase(field, operator, ignoreCase) : operator;
        if (matcher(wrapper.dataItem, term)) {
            acc.push(wrapper);
        }
        else if (wrapper.children && wrapper.children.length > 0) {
            const newItems = filterTree(wrapper.children, term, { operator, ignoreCase }, textField, depth + 1);
            if (newItems.length > 0) {
                acc.push({
                    dataItem: wrapper.dataItem,
                    children: newItems
                });
            }
        }
        return acc;
    }, []);
};
const ɵ0 = (a, b) => a.indexOf(b) >= 0, ɵ1 = (a, b) => a.indexOf(b) === -1, ɵ2 = (a, b) => a.lastIndexOf(b, 0) === 0, ɵ3 = (a, b) => a.lastIndexOf(b, 0) === -1, ɵ4 = (a, b) => a.indexOf(b, a.length - b.length) >= 0, ɵ5 = (a, b) => a.indexOf(b, a.length - b.length) < 0;
const operators = {
    contains: ɵ0,
    doesnotcontain: ɵ1,
    startswith: ɵ2,
    doesnotstartwith: ɵ3,
    endswith: ɵ4,
    doesnotendwith: ɵ5
};
const matchByCase = (matcher, ignoreCase) => (a, b) => {
    if (ignoreCase) {
        return matcher(a.toLowerCase(), b.toLowerCase());
    }
    return matcher(a, b);
};
const matchByFieldAndCase = (field, operator, ignoreCase) => (dataItem, term) => matchByCase(operators[operator], ignoreCase)(dataItem[field], term);

const last = (list) => list[list.length - 1];
const safe = node => (node || {});
const safeChildren = node => (safe(node).children || []);
const findLast = node => {
    let lastNode = node;
    let children = [].concat(safeChildren(node));
    while (children.length) {
        children = children.concat(safeChildren(last(children)));
        lastNode = children.shift();
    }
    return lastNode;
};
/**
 * @hidden
 */
class NavigationModel {
    constructor() {
        this.ib = new IndexBuilderService();
        this.nodes = [];
    }
    firstNode() {
        return this.nodes[0] || null;
    }
    lastNode() {
        const node = this.nodes[this.nodes.length - 1];
        if (!node) {
            return null;
        }
        return findLast(last(this.container(node))) || node;
    }
    closestNode(index) {
        const { prev } = safe(this.findNode(index));
        const sibling = prev || this.firstNode();
        return safe(sibling).index === index ? this.sibling(sibling, 1) : sibling;
    }
    firstFocusableNode() {
        return this.nodes.find((node) => {
            return !node.disabled && node.visible;
        });
    }
    findNode(index) {
        return this.find(index, this.nodes);
    }
    findParent(index) {
        const parentLevel = this.ib.level(index) - 1;
        return this.findNode(this.ib.indexForLevel(index, parentLevel));
    }
    findChild(index) {
        return safeChildren(this.findNode(index))[0] || null;
    }
    findPrev(item) {
        const index = item.index;
        const parent = this.findParent(index);
        const levelIndex = this.ib.lastLevelIndex(index);
        if (levelIndex === 0) {
            return parent;
        }
        const currentNode = this.findNode(index);
        let prev = this.sibling(currentNode, -1);
        if (prev) {
            let children = this.container(prev);
            while (children.length > 0) {
                prev = last(children);
                children = this.container(prev);
            }
        }
        return prev;
    }
    findNext(item) {
        const children = this.container(item);
        if (children.length === 0) {
            return this.sibling(item, 1);
        }
        return children[0];
    }
    registerItem(id, index, disabled, loadMoreButton = false, visible = true) {
        const children = [];
        const level = this.ib.level(index);
        const parent = this.findParent(index);
        if (parent || level === 1) {
            const node = { id, children, index, parent, disabled, loadMoreButton, visible };
            this.insert(node, parent);
        }
    }
    unregisterItem(id, index) {
        const node = this.find(index, this.nodes);
        if (!node || node.id !== id) {
            return;
        }
        const children = this.container(node.parent);
        children.splice(children.indexOf(node), 1);
    }
    childLevel(nodes) {
        const children = nodes.filter(node => isPresent(node));
        if (!children || !children.length) {
            return 1;
        }
        return this.ib.level(children[0].index);
    }
    container(node) {
        return node ? node.children : this.nodes;
    }
    find(index, nodes) {
        const childLevel = this.childLevel(nodes);
        const indexToMatch = this.ib.indexForLevel(index, childLevel);
        const isLeaf = childLevel === this.ib.level(index);
        const node = nodes.find(n => n && n.index === indexToMatch);
        if (!node) {
            return null;
        }
        return isLeaf ? node : this.find(index, node.children);
    }
    insert(node, parent) {
        const nodes = this.container(parent);
        nodes.splice(this.ib.lastLevelIndex(node.index), 0, node);
    }
    sibling(node, offset) {
        if (!node) {
            return null;
        }
        const parent = this.findParent(node.index);
        const container = this.container(parent);
        return container[container.indexOf(node) + offset] || this.sibling(parent, offset) || null;
    }
}

/**
 * @hidden
 */
let NavigationService = class NavigationService {
    constructor(localization) {
        this.localization = localization;
        this.expands = new Subject();
        this.moves = new Subject();
        this.checks = new Subject();
        this.selects = new Subject();
        this.loadMore = new Subject();
        this.navigable = true;
        this.actions = {
            [Keys.ArrowUp]: () => this.activate(this.model.findPrev(this.focusableItem)),
            [Keys.ArrowDown]: () => this.activate(this.model.findNext(this.focusableItem)),
            [Keys.ArrowLeft]: () => !this.isLoadMoreButton && (this.expand({
                expand: this.localization.rtl,
                intercept: this.localization.rtl ? this.moveToChild : this.moveToParent
            })),
            [Keys.ArrowRight]: () => !this.isLoadMoreButton && (this.expand({
                expand: !this.localization.rtl,
                intercept: this.localization.rtl ? this.moveToParent : this.moveToChild
            })),
            [Keys.Home]: () => this.activate(this.model.firstNode()),
            [Keys.End]: () => this.activate(this.model.lastNode()),
            [Keys.Enter]: () => this.handleEnter(),
            [Keys.Space]: () => this.handleSpace()
        };
        this.isFocused = false;
        this._model = new NavigationModel();
        this.moveToChild = this.moveToChild.bind(this);
        this.moveToParent = this.moveToParent.bind(this);
    }
    get model() {
        return this._model;
    }
    set model(model) {
        this._model = model;
    }
    get activeIndex() {
        return nodeIndex(this.activeItem) || null;
    }
    get isActiveExpanded() {
        return this.activeItem && this.activeItem.children.length > 0;
    }
    get isLoadMoreButton() {
        return this.activeItem && this.activeItem.loadMoreButton;
    }
    get focusableItem() {
        return this.activeItem || this.model.firstFocusableNode();
    }
    activate(item) {
        if (!this.navigable || !item || this.isActive(nodeIndex(item))) {
            return;
        }
        this.isFocused = true;
        this.activeItem = item || this.activeItem;
        this.notifyMove();
    }
    activateParent(index) {
        this.activate(this.model.findParent(index));
    }
    activateIndex(index) {
        if (!index) {
            return;
        }
        this.activate(this.model.findNode(index));
    }
    activateClosest(index) {
        if (!index || nodeIndex(this.focusableItem) !== index) {
            return;
        }
        this.activeItem = this.model.closestNode(index);
        this.notifyMove();
    }
    activateFocusable() {
        if (this.activeItem) {
            return;
        }
        this.activeItem = this.model.firstNode();
        this.notifyMove();
    }
    deactivate() {
        if (!this.navigable || !this.isFocused) {
            return;
        }
        this.isFocused = false;
        this.notifyMove();
    }
    checkIndex(index) {
        if (!this.isDisabled(index)) {
            this.checks.next(index);
        }
    }
    selectIndex(index) {
        if (!this.isDisabled(index)) {
            this.selects.next(index);
        }
    }
    notifyLoadMore(index) {
        if (!isPresent(index)) {
            return;
        }
        this.loadMore.next(index);
    }
    isActive(index) {
        if (!index) {
            return false;
        }
        return this.isFocused && this.activeIndex === index;
    }
    isFocusable(index) {
        return nodeIndex(this.focusableItem) === index;
    }
    isDisabled(index) {
        return this.model.findNode(index).disabled;
    }
    registerItem(id, index, disabled, loadMoreButton = false, visible = true) {
        const itemAtIndex = this.model.findNode(index);
        if (isPresent(itemAtIndex)) {
            this.model.unregisterItem(itemAtIndex.id, itemAtIndex.index);
            if (this.isActive(index)) {
                this.deactivate();
            }
        }
        this.model.registerItem(id, index, disabled, loadMoreButton, visible);
    }
    unregisterItem(id, index) {
        if (this.isActive(index)) {
            this.activateParent(index);
        }
        this.model.unregisterItem(id, index);
    }
    move(e) {
        if (!this.navigable) {
            return;
        }
        const moveAction = this.actions[e.keyCode];
        if (!moveAction) {
            return;
        }
        moveAction();
        e.preventDefault();
    }
    expand({ expand, intercept }) {
        const index = nodeIndex(this.activeItem);
        if (!index || intercept(index)) {
            return;
        }
        this.notifyExpand(expand);
    }
    moveToParent() {
        if (this.isActiveExpanded) {
            return false;
        }
        this.activate(this.model.findParent(nodeIndex(this.activeItem)));
        return true;
    }
    moveToChild() {
        if (!this.isActiveExpanded) {
            return false;
        }
        this.activate(this.model.findChild(nodeIndex(this.activeItem)));
        return true;
    }
    notifyExpand(expand) {
        this.expands.next(this.navigationState(expand));
    }
    notifyMove() {
        this.moves.next(this.navigationState());
    }
    navigationState(expand = false) {
        return ({ expand, index: this.activeIndex, isFocused: this.isFocused });
    }
    handleEnter() {
        if (!this.navigable) {
            return;
        }
        if (this.isLoadMoreButton) {
            this.notifyLoadMore(this.activeIndex);
        }
        else {
            this.selectIndex(this.activeIndex);
        }
    }
    handleSpace() {
        if (!this.navigable) {
            return;
        }
        if (this.isLoadMoreButton) {
            this.notifyLoadMore(this.activeIndex);
        }
        else {
            this.checkIndex(this.activeIndex);
        }
    }
};
NavigationService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [LocalizationService])
], NavigationService);

/**
 * @hidden
 */
let NodeChildrenService = class NodeChildrenService {
    /**
     * @hidden
     */
    constructor() {
        this.changes = new Subject();
    }
    childrenLoaded(item, children) {
        this.changes.next({ item, children });
    }
};
NodeChildrenService = __decorate([
    Injectable()
], NodeChildrenService);

/**
 * Represents the template for the TreeView nodes ([more information and example]({% slug nodetemplate_treeview %})).
 * The template helps to customize the content of the nodes. To define the node template, nest an `<ng-template>`
 * tag with the `kendoTreeViewNodeTemplate` directive inside a `<kendo-treeview>` tag.
 *
 *
 * The node data item and its hierarchical index are available as context variables:
 *
 * - `let-dataItem` (`any`) - available as implicit context variable
 * - `let-index="index"` (`string`)
 *
 *
 * @example
 * ```ts
 *
 *  import { Component } from '@angular/core';
 *  @Component({
 *      selector: 'my-app',
 *      template: `
 *      <kendo-treeview
 *          [nodes]="data"
 *          kendoTreeViewExpandable
 *
 *          kendoTreeViewHierarchyBinding
 *          childrenField="items">
 *        <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
 *          <span [style.fontWeight]="dataItem.items ? 'bolder': 'normal' ">{{ index }}: {{ dataItem.text }}</span>
 *        </ng-template>
 *      </kendo-treeview>
 *    `
 *  })
 *  export class AppComponent {
 *      public data: any[] = [
 *          {
 *              text: "Inbox",
 *              items: [{ text: "Read Mail" }]
 *          },
 *          {
 *              text: "Drafts"
 *          },
 *          {
 *              text: "Search Folders",
 *              items: [
 *                  { text: "Categorized Mail" },
 *                  { text: "Large Mail" },
 *                  { text: "Unread Mail"}
 *              ]
 *          },
 *          { text: "Settings" }
 *      ];
 *  }
 *
 * ```
 */
let NodeTemplateDirective = class NodeTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
NodeTemplateDirective = __decorate([
    Directive({
        selector: '[kendoTreeViewNodeTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], NodeTemplateDirective);

/**
 * Represents the template for the TreeView load more buttons.
 * To define a button template, nest an `<ng-template>`
 * tag with the `kendoTreeViewLoadMoreButtonTemplate` directive inside a `<kendo-treeview>` tag
 * ([see example]({% slug loadmorebutton_treeview %}#toc-button-template)).
 *
 * The hierarchical index of the load more button node is available as a context variable:
 *
 * - `let-index="index"` (`string`)
 */
let LoadMoreButtonTemplateDirective = class LoadMoreButtonTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
LoadMoreButtonTemplateDirective = __decorate([
    Directive({
        selector: '[kendoTreeViewLoadMoreButtonTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], LoadMoreButtonTemplateDirective);

/**
 * @hidden
 *
 * An injection token used by the data binding directives to interface with
 * the TreeView or the DropDownTree components.
 */
let DataBoundComponent = class DataBoundComponent {
};
DataBoundComponent = __decorate([
    Injectable()
], DataBoundComponent);

/**
 * @hidden
 *
 * An injection token used by the expand-directive to interface with
 * the TreeView or the DropDownTree components.
 */
let ExpandableComponent = class ExpandableComponent {
};
ExpandableComponent = __decorate([
    Injectable()
], ExpandableComponent);

/**
 * @hidden
 */
let SelectionService = class SelectionService {
    /**
     * @hidden
     */
    constructor() {
        this.changes = new Subject();
    }
    isFirstSelected(index) {
        return this.firstIndex === index;
    }
    setFirstSelected(index, selected) {
        if (this.firstIndex === index && selected === false) {
            this.firstIndex = null;
        }
        else if (!this.firstIndex && selected) {
            this.firstIndex = index;
        }
    }
    select(index, dataItem) {
        this.changes.next({ dataItem, index });
    }
};
SelectionService = __decorate([
    Injectable()
], SelectionService);

const INDEX_REGEX = /\d+$/;
/**
 * @hidden
 */
let TreeViewLookupService = class TreeViewLookupService {
    /**
     * @hidden
     */
    constructor() {
        this.map = new Map();
    }
    registerItem(item, parent) {
        const currentLookup = {
            children: [],
            item,
            parent: this.item(nodeIndex(parent))
        };
        this.map.set(item.index, currentLookup);
    }
    registerChildren(index, children) {
        const item = this.item(index);
        if (!item) {
            return;
        }
        item.children = children;
    }
    unregisterItem(index, dataItem) {
        const current = this.item(index);
        if (current && current.item.dataItem === dataItem) {
            this.map.delete(index);
            if (current.parent && current.parent.children) {
                current.parent.children = current.parent.children.filter(item => item.dataItem !== dataItem);
            }
        }
    }
    replaceItem(index, item, parent) {
        if (!item) {
            return;
        }
        this.unregisterItem(index, item.dataItem);
        this.registerItem(item, parent);
        this.addToParent(item, parent);
    }
    itemLookup(index) {
        const item = this.item(index);
        if (!item) {
            return null;
        }
        return {
            children: this.mapChildren(item.children),
            item: item.item,
            parent: item.parent
        };
    }
    hasItem(index) {
        return this.map.has(index);
    }
    item(index) {
        return this.map.get(index) || null;
    }
    addToParent(item, parent) {
        if (parent) {
            const parentItem = this.item(parent.index);
            const index = parseInt(INDEX_REGEX.exec(item.index)[0], 10);
            parentItem.children = parentItem.children || [];
            parentItem.children.splice(index, 0, item);
        }
    }
    mapChildren(children = []) {
        return children.map(c => {
            const { item, parent, children } = this.item(c.index);
            return {
                children: this.mapChildren(children),
                item,
                parent
            };
        });
    }
};
TreeViewLookupService = __decorate([
    Injectable()
], TreeViewLookupService);

const LOAD_MORE_DOC_LINK = 'http://www.telerik.com/kendo-angular-ui/components/treeview/load-more-button/';
const providers = [
    ExpandStateService,
    IndexBuilderService,
    TreeViewLookupService,
    LoadingNotificationService,
    NodeChildrenService,
    NavigationService,
    SelectionService,
    DataChangeNotificationService,
    LocalizationService,
    {
        provide: L10N_PREFIX,
        useValue: 'kendo.treeview'
    },
    {
        provide: DataBoundComponent,
        useExisting: forwardRef(() => TreeViewComponent)
    },
    {
        provide: ExpandableComponent,
        useExisting: forwardRef(() => TreeViewComponent)
    }
];
/* tslint:disable:member-ordering */
/**
 * Represents the [Kendo UI TreeView component for Angular]({% slug overview_treeview %}).
 *
 * @example
 * {% meta height:350 %}
 * {% embed_file basic-usage/app.component.ts preview %}
 * {% embed_file basic-usage/app.module.ts %}
 * {% endmeta %}
 */
let TreeViewComponent = class TreeViewComponent {
    constructor(element, expandService, navigationService, nodeChildrenService, selectionService, treeViewLookupService, ngZone, renderer, dataChangeNotification, localization) {
        this.element = element;
        this.expandService = expandService;
        this.navigationService = navigationService;
        this.nodeChildrenService = nodeChildrenService;
        this.selectionService = selectionService;
        this.treeViewLookupService = treeViewLookupService;
        this.ngZone = ngZone;
        this.renderer = renderer;
        this.dataChangeNotification = dataChangeNotification;
        this.localization = localization;
        this.classNames = true;
        this.role = 'tree';
        /**
         * The hint which is displayed when the component is empty.
         */
        this.filterInputPlaceholder = "";
        /** @hidden */
        this.fetchNodes = () => this.data;
        /**
         * Fires when the children of the expanded node are loaded.
         */
        this.childrenLoaded = new EventEmitter();
        /**
         * Fires when the user blurs the component.
         */
        this.onBlur = new EventEmitter();
        /**
         * Fires when the user focuses the component.
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires when the user expands a TreeView node.
         */
        this.expand = new EventEmitter();
        /**
         * Fires when the user collapses a TreeView node.
         */
        this.collapse = new EventEmitter();
        /**
         * Fires just before the dragging of the node starts ([see example]({% slug draganddrop_treeview %}#toc-setup)). This event is preventable.
         * If you prevent the event default, no drag hint will be created and the subsequent drag-related events will not be fired.
         */
        this.nodeDragStart = new EventEmitter();
        /**
         * Fires when an item is being dragged ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         */
        this.nodeDrag = new EventEmitter();
        /**
         * Fires on the target TreeView when a dragged item is dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         * This event is preventable. If you prevent the event default (`event.preventDefualt()`) or invalidate its state (`event.setValid(false)`),
         * the `addItem` and `removeItem` events will not be triggered.
         *
         * Both operations cancel the default drop operation, but the indication to the user is different. `event.setValid(false)` indicates that the operation was
         * unsuccessful by animating the drag clue to its original position. `event.preventDefault()` simply removes the clue, as if it has been dropped successfully.
         * As a general rule, use `preventDefault` to manually handle the add and remove operations, and `setValid(false)` to indicate the operation was unsuccessful.
         */
        this.nodeDrop = new EventEmitter();
        /**
         * Fires on the source TreeView after the dragged item has been dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         */
        this.nodeDragEnd = new EventEmitter();
        /**
         * Fires after a dragged item is dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         * Called on the TreeView where the item is dropped.
         */
        this.addItem = new EventEmitter();
        /**
         * Fires after a dragged item is dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         * Called on the TreeView from where the item is dragged.
         */
        this.removeItem = new EventEmitter();
        /**
         * Fires when the user selects a TreeView node checkbox
         * ([see example]({% slug checkboxes_treeview %}#toc-modifying-the-checked-state)).
         */
        this.checkedChange = new EventEmitter();
        /**
         * Fires when the user selects a TreeView node
         * ([see example]({% slug selection_treeview %}#toc-modifying-the-selection)).
         */
        this.selectionChange = new EventEmitter();
        /**
         * Fires when the value of the built-in filter input element changes.
         */
        this.filterChange = new EventEmitter();
        /**
         * Fires when the user clicks a TreeView node.
         */
        this.nodeClick = new EventEmitter();
        /**
         * Fires when the user double clicks a TreeView node.
         */
        this.nodeDblClick = new EventEmitter();
        /**
         * A function that defines how to track node changes.
         * By default, the TreeView tracks the nodes by data item object reference.
         *
         * @example
         * ```ts
         *  @Component({
         *      selector: 'my-app',
         *      template: `
         *          <kendo-treeview
         *              [nodes]="data"
         *              [textField]="'text'"
         *              [trackBy]="trackBy"
         *          >
         *          </kendo-treeview>
         *      `
         *  })
         *  export class AppComponent {
         *      public data: any[] = [
         *          { text: "Furniture" },
         *          { text: "Decor" }
         *      ];
         *
         *      public trackBy(index: number, item: any): any {
         *          return item.text;
         *      }
         *  }
         * ```
         */
        this.trackBy = trackBy;
        /**
         * A function which determines if a specific node is disabled.
         */
        this.isDisabled = isDisabled;
        /**
         * A callback which determines whether a TreeView node should be rendered as hidden. The utility .k-display-none class is used to hide the nodes.
         * Useful for custom filtering implementations.
         */
        this.isVisible = isVisible;
        /**
         * Determines whether the TreeView keyboard navigable is enabled.
         */
        this.navigable = true;
        /**
         * A function which provides the child nodes for a given parent node
         * ([see example]({% slug databinding_treeview %})).
         */
        this.children = () => of([]);
        /**
         * Renders the built-in input element for filtering the TreeView.
         * If set to `true`, the component emits the `filterChange` event, which can be used to [filter the TreeView manually]({% slug filtering_treeview %}#toc-manual-filtering).
         * A built-in filtering implementation is available to use with the [`kendoTreeViewHierarchyBinding`]({% slug api_treeview_hierarchybindingdirective %}) and [`kendoTreeViewFlatDataBinding`]({% slug api_treeview_flatdatabindingdirective %}) directives.
         */
        this.filterable = false;
        /**
         * Sets an initial value of the built-in input element used for filtering.
         */
        this.filter = '';
        this.checkboxes = false;
        this.expandIcons = false;
        this.selectable = false;
        this.touchActions = true;
        this.isActive = false;
        this.data = new BehaviorSubject([]);
        this._animate = true;
        this.subscriptions = new Subscription();
        this.domSubscriptions = [];
        validatePackage(packageMetadata);
    }
    /** @hidden */
    get direction() {
        return this.localization.rtl ? 'rtl' : 'ltr';
    }
    /**
     * Determines whether the content animation is enabled.
     */
    set animate(value) {
        this._animate = value;
    }
    get animate() {
        return !this._animate;
    }
    /**
     * @hidden
     */
    set nodeTemplateRef(template) {
        this.nodeTemplate = template;
    }
    /**
     * @hidden
     */
    set loadMoreButtonTemplateRef(template) {
        this.loadMoreButtonTemplate = template;
    }
    /**
     * The nodes which will be displayed by the TreeView
     * ([see example]({% slug databinding_treeview %})).
     */
    set nodes(value) {
        this.dataChangeNotification.notify();
        this.data.next(value);
    }
    get nodes() {
        return this.data.value;
    }
    /**
     * A function which determines if a specific node has child nodes
     * ([see example]({% slug databinding_treeview %})).
     */
    get hasChildren() {
        return this._hasChildren || hasChildren;
    }
    set hasChildren(callback) {
        this._hasChildren = callback;
        this.expandIcons = Boolean(this._isExpanded && this._hasChildren);
    }
    /**
     * A function which determines if a specific node is checked
     * ([see example]({% slug checkboxes_treeview %}#toc-modifying-the-checked-state)).
     */
    get isChecked() {
        return this._isChecked || isChecked;
    }
    set isChecked(callback) {
        this._isChecked = callback;
        this.checkboxes = Boolean(this._isChecked);
    }
    /**
     * A function which determines if a specific node is expanded.
     */
    get isExpanded() {
        return this._isExpanded || isExpanded;
    }
    set isExpanded(callback) {
        this._isExpanded = callback;
        this.expandIcons = Boolean(this._isExpanded && this._hasChildren);
    }
    /**
     * A function which determines if a specific node is selected
     * ([see example]({% slug selection_treeview %}#toc-modifying-the-selection)).
     */
    get isSelected() {
        return this._isSelected || isSelected;
    }
    set isSelected(callback) {
        this._isSelected = callback;
        this.selectable = Boolean(this._isSelected);
    }
    ngOnChanges(_) {
        this.navigationService.navigable = Boolean(this.navigable);
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.domSubscriptions.forEach(subscription => subscription());
    }
    ngOnInit() {
        this.subscriptions.add(this.nodeChildrenService
            .changes
            .subscribe((x) => this.childrenLoaded.emit(x)));
        this.subscriptions.add(this.expandService.changes
            .subscribe(({ index, dataItem, expand }) => expand
            ? this.expand.emit({ index, dataItem })
            : this.collapse.emit({ index, dataItem })));
        this.subscriptions.add(this.navigationService.checks
            .subscribe((x) => this.checkedChange.emit(this.treeViewLookupService.itemLookup(x))));
        this.subscriptions.add(this.selectionService.changes
            .subscribe((x) => {
            if (hasObservers(this.selectionChange)) {
                this.ngZone.run(() => {
                    this.selectionChange.emit(x);
                });
            }
        }));
        if (this.element) {
            this.ngZone.runOutsideAngular(() => {
                this.attachDomHandlers();
            });
        }
    }
    /**
     * Blurs the focused TreeView item.
     */
    blur() {
        if (!isDocumentAvailable()) {
            return;
        }
        const target = focusableNode(this.element);
        if (document.activeElement === target) {
            target.blur();
        }
    }
    /**
     * Focuses the first focusable item in the TreeView component if no hierarchical index is provided.
     *
     * @example
     * ```ts
     * import { Component } from '@angular/core';
     *
     *  @Component({
     *      selector: 'my-app',
     *      template: `
     *      <button (click)="treeview.focus('1')">Focuses the second node</button>
     *      <kendo-treeview
     *          #treeview
     *          [nodes]="data"
     *          textField="text"
     *      >
     *      </kendo-treeview>
     *  `
     *  })
     *  export class AppComponent {
     *      public data: any[] = [
     *          { text: "Furniture" },
     *          { text: "Decor" }
     *      ];
     *  }
     * ```
     */
    focus(index) {
        const focusIndex = index || nodeIndex(this.navigationService.focusableItem);
        this.navigationService.activateIndex(focusIndex);
        const target = focusableNode(this.element);
        if (target) {
            target.focus();
        }
    }
    /**
     * Based on the specified index, returns the TreeItemLookup node.
     *
     * @param index - The index of the node.
     * @returns {TreeItemLookup} - The item that was searched (looked up).
     */
    itemLookup(index) {
        return this.treeViewLookupService.itemLookup(index);
    }
    /**
     * Triggers the [`children`]({% slug api_treeview_treeviewcomponent %}#toc-children) function for every expanded node,
     * causing all rendered child nodes to be fetched again.
     */
    rebindChildren() {
        this.dataChangeNotification.notify();
    }
    /**
     * @hidden
     */
    isDisabledNode(node) {
        return this.navigationService.isDisabled(node.item.index);
    }
    /**
     * Triggers the `expand` event for the provided node and displays it's loading indicator.
     */
    expandNode(item, index) {
        this.expandService.expand(index, item);
    }
    /**
     * Triggers the `collapse` event for the provided node.
     */
    collapseNode(item, index) {
        this.expandService.collapse(index, item);
    }
    /**
     * Gets the current page size of the checked data item children collection
     * ([see example]({% slug loadmorebutton_treeview %}#toc-managing-page-sizes)).
     *
     * > Since the root nodes collection is not associated with any parent data item, pass `null` as `dataItem` param to get its page size.
     *
     * @param dataItem {any} - The parent data item of the targeted collection.
     * @returns {number} - The page size of the checked data item children collection.
     */
    getNodePageSize(dataItem) {
        this.verifyLoadMoreService();
        return this.loadMoreService.getGroupSize(dataItem);
    }
    /**
     * Sets the page size of the targeted data item children collection
     * ([see example]({% slug loadmorebutton_treeview %}#toc-managing-page-sizes)).
     *
     * > Since the root nodes collection is not associated with any parent data item, pass `null` as `dataItem` param to target its page size.
     *
     * @param dataItem {any} - The parent data item of the targeted collection.
     * @param pageSize {number} - The new page size.
     */
    setNodePageSize(dataItem, pageSize) {
        this.verifyLoadMoreService();
        this.loadMoreService.setGroupSize(dataItem, pageSize);
    }
    attachDomHandlers() {
        const element = this.element.nativeElement;
        this.clickHandler = this.clickHandler.bind(this);
        this.domSubscriptions.push(this.renderer.listen(element, 'contextmenu', this.clickHandler), this.renderer.listen(element, 'click', this.clickHandler), this.renderer.listen(element, 'dblclick', this.clickHandler), this.renderer.listen(element, 'focusin', this.focusHandler.bind(this)), this.renderer.listen(element, 'focusout', this.blurHandler.bind(this)), this.renderer.listen(element, 'keydown', this.keydownHandler.bind(this)));
    }
    focusHandler(e) {
        let focusItem;
        if (match(e.target, '.k-treeview-item')) {
            focusItem = e.target;
        }
        else if (!isFocusable(e.target)) { // with compliments to IE
            focusItem = closestNode(e.target);
        }
        if (focusItem) {
            this.navigationService.activateIndex(nodeId(e.target));
            if (!this.isActive && hasObservers(this.onFocus)) {
                this.ngZone.run(() => {
                    this.onFocus.emit();
                });
            }
            this.isActive = true;
        }
    }
    blurHandler(e) {
        if (this.isActive && match(e.target, '.k-treeview-item') &&
            (!e.relatedTarget || !match(e.relatedTarget, '.k-treeview-item') || !hasParent(e.relatedTarget, this.element.nativeElement))) {
            this.navigationService.deactivate();
            this.isActive = false;
            if (hasObservers(this.onBlur)) {
                this.ngZone.run(() => {
                    this.onBlur.emit();
                });
            }
        }
    }
    clickHandler(e) {
        const target = e.target;
        if ((e.type === 'contextmenu' && !hasObservers(this.nodeClick)) ||
            (e.type === 'click' && !hasObservers(this.nodeClick) && !hasObservers(this.selectionChange) && !isLoadMoreButton(target)) ||
            (e.type === 'dblclick' && !hasObservers(this.nodeDblClick)) || isFocusable(target) ||
            (!isContent(target) && !isLoadMoreButton(target)) || !hasParent(target, this.element.nativeElement)) {
            return;
        }
        const index = nodeId(closestNode(target));
        // the disabled check is probably not needed due to the k-state-disabled styles
        if (!index || this.navigationService.isDisabled(index)) {
            return;
        }
        this.ngZone.run(() => {
            const lookup = this.treeViewLookupService.itemLookup(index);
            if (e.type === 'click') {
                const loadMoreButton = this.navigationService.model.findNode(index).loadMoreButton;
                if (loadMoreButton) {
                    this.navigationService.notifyLoadMore(index);
                }
                else {
                    this.navigationService.selectIndex(index);
                }
            }
            const emitter = e.type === 'dblclick' ? this.nodeDblClick : this.nodeClick;
            emitter.emit({
                item: lookup.item,
                originalEvent: e,
                type: e.type
            });
        });
    }
    keydownHandler(e) {
        if (this.isActive && this.navigable) {
            this.ngZone.run(() => {
                this.navigationService.move(e);
            });
        }
    }
    verifyLoadMoreService() {
        if (isDevMode() && !isPresent(this.loadMoreService)) {
            throw new Error(`To use the TreeView paging functionality, you need to assign the \`kendoTreeViewLoadMore\` directive. See ${LOAD_MORE_DOC_LINK}.`);
        }
    }
};
__decorate([
    HostBinding("class.k-widget"),
    HostBinding("class.k-treeview"),
    __metadata("design:type", Boolean)
], TreeViewComponent.prototype, "classNames", void 0);
__decorate([
    HostBinding("attr.role"),
    __metadata("design:type", String)
], TreeViewComponent.prototype, "role", void 0);
__decorate([
    HostBinding("attr.dir"),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TreeViewComponent.prototype, "direction", null);
__decorate([
    ViewChild('assetsContainer', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], TreeViewComponent.prototype, "assetsContainer", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TreeViewComponent.prototype, "filterInputPlaceholder", void 0);
__decorate([
    Input(),
    HostBinding('@.disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], TreeViewComponent.prototype, "animate", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "childrenLoaded", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "onBlur", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "onFocus", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "expand", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "collapse", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "nodeDragStart", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "nodeDrag", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "nodeDrop", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "nodeDragEnd", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "addItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "removeItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "checkedChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "selectionChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "filterChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "nodeClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TreeViewComponent.prototype, "nodeDblClick", void 0);
__decorate([
    ContentChild(NodeTemplateDirective, { static: true }),
    __metadata("design:type", NodeTemplateDirective)
], TreeViewComponent.prototype, "nodeTemplate", void 0);
__decorate([
    Input('nodeTemplate'),
    __metadata("design:type", NodeTemplateDirective),
    __metadata("design:paramtypes", [NodeTemplateDirective])
], TreeViewComponent.prototype, "nodeTemplateRef", null);
__decorate([
    ContentChild(LoadMoreButtonTemplateDirective, { static: true }),
    __metadata("design:type", LoadMoreButtonTemplateDirective)
], TreeViewComponent.prototype, "loadMoreButtonTemplate", void 0);
__decorate([
    Input('loadMoreButtonTemplate'),
    __metadata("design:type", LoadMoreButtonTemplateDirective),
    __metadata("design:paramtypes", [LoadMoreButtonTemplateDirective])
], TreeViewComponent.prototype, "loadMoreButtonTemplateRef", null);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewComponent.prototype, "trackBy", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], TreeViewComponent.prototype, "nodes", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TreeViewComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], TreeViewComponent.prototype, "hasChildren", null);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], TreeViewComponent.prototype, "isChecked", null);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewComponent.prototype, "isDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], TreeViewComponent.prototype, "isExpanded", null);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], TreeViewComponent.prototype, "isSelected", null);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewComponent.prototype, "isVisible", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewComponent.prototype, "navigable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewComponent.prototype, "children", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewComponent.prototype, "filterable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TreeViewComponent.prototype, "filter", void 0);
TreeViewComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.Default,
        exportAs: 'kendoTreeView',
        providers: providers,
        selector: 'kendo-treeview',
        template: `
        <kendo-textbox
            #filterInput
            *ngIf="filterable"
            [value]="filter"
            [clearButton]="true"
            (valueChange)="filterChange.emit($event)"
            [placeholder]="filterInputPlaceholder"
        >
            <ng-template kendoTextBoxPrefixTemplate>
                <span class="k-icon k-i-search"></span>
            </ng-template>
        </kendo-textbox>
        <ul class="k-treeview-lines"
            kendoTreeViewGroup
            role="group"
            [checkboxes]="checkboxes"
            [expandIcons]="expandIcons"
            [selectable]="selectable"
            [touchActions]="touchActions"
            [children]="children"
            [hasChildren]="hasChildren"
            [isChecked]="isChecked"
            [isDisabled]="isDisabled"
            [isExpanded]="isExpanded"
            [isSelected]="isSelected"
            [isVisible]="isVisible"
            [nodeTemplateRef]="nodeTemplate?.templateRef"
            [loadMoreButtonTemplateRef]="loadMoreButtonTemplate?.templateRef"
            [textField]="textField"
            [nodes]="fetchNodes"
            [loadMoreService]="loadMoreService"
            [trackBy]="trackBy"
        >
        </ul>
        <ng-container #assetsContainer></ng-container>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        ExpandStateService,
        NavigationService,
        NodeChildrenService,
        SelectionService,
        TreeViewLookupService,
        NgZone,
        Renderer2,
        DataChangeNotificationService,
        LocalizationService])
], TreeViewComponent);

/**
 * @hidden
 */
let TreeViewGroupComponent = class TreeViewGroupComponent {
    constructor(expandService, loadingService, indexBuilder, treeViewLookupService, navigationService, nodeChildrenService, dataChangeNotification, changeDetectorRef) {
        this.expandService = expandService;
        this.loadingService = loadingService;
        this.indexBuilder = indexBuilder;
        this.treeViewLookupService = treeViewLookupService;
        this.navigationService = navigationService;
        this.nodeChildrenService = nodeChildrenService;
        this.dataChangeNotification = dataChangeNotification;
        this.changeDetectorRef = changeDetectorRef;
        this.kGroupClass = true;
        this.role = 'group';
        this.textField = "";
        this.initialNodesLoaded = false;
        this.loadingMoreNodes = false;
        this._data = [];
        this.singleRecordSubscriptions = new Subscription();
        this.isChecked = () => 'none';
        this.isDisabled = () => false;
        this.isExpanded = () => false;
        this.isVisible = () => true;
        this.isSelected = () => false;
        this.children = () => of([]);
        this.hasChildren = () => false;
    }
    get moreNodesAvailable() {
        if (!isPresent(this.loadMoreService)) {
            return false;
        }
        return this.pageSize < this.totalNodesCount;
    }
    get pageSize() {
        if (!isPresent(this.loadMoreService)) {
            return null;
        }
        return this.loadMoreService.getGroupSize(this.parentDataItem);
    }
    set pageSize(pageSize) {
        this.loadMoreService.setGroupSize(this.parentDataItem, pageSize);
    }
    get data() {
        if (isPresent(this.pageSize)) {
            const normalizedSizeValue = this.pageSize > 0 ? this.pageSize : 0;
            return this._data.slice(0, normalizedSizeValue);
        }
        return this._data;
    }
    set data(data) {
        this._data = data;
        this.registerLoadedNodes(this.data);
    }
    get loadMoreButtonIndex() {
        if (!this.loadMoreService) {
            return null;
        }
        return this.nodeIndex(this.data.length);
    }
    /**
     * Represents the total number of nodes for the current level.
     */
    get totalNodesCount() {
        if (!this.loadMoreService) {
            return this.data.length;
        }
        return this.loadMoreService.getTotalNodesCount(this.parentDataItem, this._data.length);
    }
    get hasTemplate() {
        return isPresent(this.nodeTemplateRef);
    }
    expandNode(index, dataItem, expand) {
        if (expand) {
            this.expandService.expand(index, dataItem);
        }
        else {
            this.expandService.collapse(index, dataItem);
        }
    }
    checkNode(index) {
        this.navigationService.checkIndex(index);
        this.navigationService.activateIndex(index);
    }
    nodeIndex(index) {
        return this.indexBuilder.nodeIndex(index.toString(), this.parentIndex);
    }
    nodeText(dataItem) {
        const textField = isArray(this.textField) ? this.textField[0] : this.textField;
        return getter(textField)(dataItem);
    }
    ngOnDestroy() {
        if (isPresent(this.nodesSubscription)) {
            this.nodesSubscription.unsubscribe();
        }
        if (isPresent(this.loadMoreNodesSubscription)) {
            this.loadMoreNodesSubscription.unsubscribe();
        }
        this.singleRecordSubscriptions.unsubscribe();
    }
    ngOnInit() {
        this.subscribeToNodesChange();
        this.singleRecordSubscriptions.add(this.dataChangeNotification
            .changes
            .subscribe(this.subscribeToNodesChange.bind(this)));
        this.singleRecordSubscriptions.add(this.navigationService.loadMore
            .pipe(filter(index => index === this.loadMoreButtonIndex))
            .subscribe(this.loadMoreNodes.bind(this)));
    }
    ngOnChanges(changes) {
        if (changes.parentIndex) {
            this.setNodeChildren(this.mapToTreeItem(this.data));
        }
    }
    fetchChildren(node, index) {
        return this.children(node)
            .pipe(catchError(() => {
            this.loadingService.notifyLoaded(index);
            return EMPTY;
        }), tap(() => this.loadingService.notifyLoaded(index)));
    }
    get nextFields() {
        if (isArray(this.textField)) {
            return this.textField.length > 1 ? this.textField.slice(1) : this.textField;
        }
        return [this.textField];
    }
    loadMoreNodes() {
        if (isPresent(this.loadMoreService.loadMoreNodes)) {
            this.fetchMoreNodes();
        }
        else {
            this.loadMoreLocalNodes();
        }
    }
    loadMoreLocalNodes() {
        const initialLoadMoreButtonIndex = this.loadMoreButtonIndex;
        this.pageSize += this.loadMoreService.getInitialPageSize(this.parentDataItem);
        this.registerLoadedNodes(this.data);
        // forces the new items to be registered before the focus is changed
        this.changeDetectorRef.detectChanges();
        this.reselectItemAt(initialLoadMoreButtonIndex);
    }
    fetchMoreNodes() {
        if (this.loadingMoreNodes) {
            return;
        }
        this.loadingMoreNodes = true;
        if (isPresent(this.loadMoreNodesSubscription)) {
            this.loadMoreNodesSubscription.unsubscribe();
        }
        this.loadMoreNodesSubscription = this.loadMoreService
            .loadMoreNodes({
            dataItem: this.parentDataItem,
            skip: this.data.length,
            take: this.loadMoreService.getInitialPageSize(this.parentDataItem)
        })
            .pipe(finalize(() => this.loadingMoreNodes = false))
            .subscribe(items => {
            if (!(Array.isArray(items) && items.length > 0)) {
                return;
            }
            const initialLoadMoreButtonIndex = this.loadMoreButtonIndex;
            this.pageSize += items.length;
            this.data = this.data.concat(items);
            if (this.navigationService.isActive(initialLoadMoreButtonIndex)) {
                // forces the new items to be registered before the focus is changed
                this.changeDetectorRef.detectChanges();
                this.reselectItemAt(initialLoadMoreButtonIndex);
            }
        });
    }
    setNodeChildren(children) {
        this.treeViewLookupService.registerChildren(this.parentIndex, children);
    }
    mapToTreeItem(data) {
        if (!this.parentIndex) {
            return [];
        }
        return data.map((dataItem, idx) => ({ dataItem, index: this.nodeIndex(idx) }));
    }
    emitChildrenLoaded(children) {
        if (!this.parentIndex) {
            return;
        }
        // ignores the registered load-more button
        const contentChildren = children.filter(item => item.dataItem);
        this.nodeChildrenService.childrenLoaded({ dataItem: this.parentDataItem, index: this.parentIndex }, contentChildren);
    }
    subscribeToNodesChange() {
        if (this.nodesSubscription) {
            this.nodesSubscription.unsubscribe();
        }
        this.nodesSubscription = this.nodes(this.parentDataItem, this.parentIndex)
            .subscribe(data => {
            this.data = data;
            this.initialNodesLoaded = true;
        });
    }
    reselectItemAt(index) {
        if (!isPresent(index)) {
            return;
        }
        // make sure the old index is cleared first
        this.navigationService.deactivate();
        this.navigationService.activateIndex(index);
    }
    registerLoadedNodes(nodes = []) {
        const mappedChildren = this.mapToTreeItem(nodes);
        this.setNodeChildren(mappedChildren);
        this.emitChildrenLoaded(mappedChildren);
    }
};
__decorate([
    HostBinding("class.k-group"),
    __metadata("design:type", Boolean)
], TreeViewGroupComponent.prototype, "kGroupClass", void 0);
__decorate([
    HostBinding("attr.role"),
    __metadata("design:type", String)
], TreeViewGroupComponent.prototype, "role", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewGroupComponent.prototype, "checkboxes", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewGroupComponent.prototype, "expandIcons", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewGroupComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewGroupComponent.prototype, "selectable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewGroupComponent.prototype, "touchActions", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "trackBy", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "nodes", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TreeViewGroupComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TreeViewGroupComponent.prototype, "parentDataItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TreeViewGroupComponent.prototype, "parentIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], TreeViewGroupComponent.prototype, "nodeTemplateRef", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], TreeViewGroupComponent.prototype, "loadMoreButtonTemplateRef", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TreeViewGroupComponent.prototype, "loadMoreService", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "isChecked", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "isDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "isExpanded", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "isVisible", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "isSelected", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "children", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewGroupComponent.prototype, "hasChildren", void 0);
TreeViewGroupComponent = __decorate([
    Component({
        animations: [
            trigger('toggle', [
                transition('void => *', [
                    style({ height: 0 }),
                    animate('0.1s ease-in', style({ height: "*" }))
                ]),
                transition('* => void', [
                    style({ height: "*" }),
                    animate('0.1s ease-in', style({ height: 0 }))
                ])
            ])
        ],
        selector: '[kendoTreeViewGroup]',
        template: `
        <li
            *ngFor="let node of data; let index = index; trackBy: trackBy"
            class="k-item k-treeview-item"
            [class.k-display-none]="!isVisible(node, nodeIndex(index))"
            kendoTreeViewItem
            [attr.aria-setsize]="totalNodesCount"
            [dataItem]="node"
            [index]="nodeIndex(index)"
            [parentDataItem]="parentDataItem"
            [parentIndex]="parentIndex"
            [checkable]="checkboxes"
            [isChecked]="isChecked(node, nodeIndex(index))"
            [isDisabled]="disabled || isDisabled(node, nodeIndex(index))"
            [isVisible]="isVisible(node, nodeIndex(index))"
            [expandable]="expandIcons && hasChildren(node)"
            [isExpanded]="isExpanded(node, nodeIndex(index))"
            [selectable]="selectable"
            [isSelected]="isSelected(node, nodeIndex(index))"
            [attr.data-treeindex]="nodeIndex(index)"
        >
            <div class="k-mid">
                <span
                    class="k-icon"
                    [class.k-i-collapse]="isExpanded(node, nodeIndex(index))"
                    [class.k-i-expand]="!isExpanded(node, nodeIndex(index))"
                    [kendoTreeViewLoading]="nodeIndex(index)"
                    (click)="expandNode(nodeIndex(index), node, !isExpanded(node, nodeIndex(index)))"
                    *ngIf="expandIcons && hasChildren(node)"
                >
                </span>
                <kendo-checkbox
                    *ngIf="checkboxes"
                    [node]="node"
                    [index]="nodeIndex(index)"
                    [isChecked]="isChecked"
                    (checkStateChange)="checkNode(nodeIndex(index))"
                    tabindex="-1"
                ></kendo-checkbox>
                <span kendoTreeViewItemContent
                    [attr.data-treeindex]="nodeIndex(index)"
                    [dataItem]="node"
                    [index]="nodeIndex(index)"
                    [initialSelection]="isSelected(node, nodeIndex(index))"
                    [isSelected]="isSelected"
                    class="k-in"
                    [style.touch-action]="touchActions ? '' : 'none'"
                >
                    <ng-container [ngSwitch]="hasTemplate">
                        <ng-container *ngSwitchCase="true">
                            <ng-template
                                [ngTemplateOutlet]="nodeTemplateRef"
                                [ngTemplateOutletContext]="{
                                    $implicit: node,
                                    index: nodeIndex(index)
                                }"
                            >
                            </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{nodeText(node)}}
                        </ng-container>
                    </ng-container>
                </span>
            </div>
            <ul
                *ngIf="isExpanded(node, nodeIndex(index)) && hasChildren(node)"
                kendoTreeViewGroup
                role="group"
                [nodes]="fetchChildren"
                [checkboxes]="checkboxes"
                [expandIcons]="expandIcons"
                [selectable]="selectable"
                [touchActions]="touchActions"
                [children]="children"
                [hasChildren]="hasChildren"
                [isChecked]="isChecked"
                [isDisabled]="isDisabled"
                [disabled]="disabled || isDisabled(node, nodeIndex(index))"
                [isExpanded]="isExpanded"
                [isSelected]="isSelected"
                [isVisible]="isVisible"
                [nodeTemplateRef]="nodeTemplateRef"
                [loadMoreButtonTemplateRef]="loadMoreButtonTemplateRef"
                [parentIndex]="nodeIndex(index)"
                [parentDataItem]="node"
                [textField]="nextFields"
                [loadMoreService]="loadMoreService"
                [@toggle]="true"
                [trackBy]="trackBy"
            >
            </ul>
        </li>
        <li
            *ngIf="initialNodesLoaded && moreNodesAvailable"
            class="k-item k-treeview-item"
            [class.k-treeview-load-more-checkboxes-container]="checkboxes"
            kendoTreeViewItem
            [role]="'button'"
            [selectable]="false"
            [checkable]="false"
            [expandable]="false"
            [index]="loadMoreButtonIndex"
            [parentDataItem]="parentDataItem"
            [parentIndex]="parentIndex"
            [attr.data-treeindex]="loadMoreButtonIndex"
        >
            <div class="k-mid">
                <span
                    *ngIf="loadingMoreNodes"
                    class="k-icon k-i-loading k-i-expand"
                >
                </span>
                <span
                    class="k-in k-treeview-load-more-button"
                    [attr.data-treeindex]="loadMoreButtonIndex"
                    kendoTreeViewItemContent
                    [index]="loadMoreButtonIndex"
                >
                    <ng-template
                        *ngIf="loadMoreButtonTemplateRef"
                        [ngTemplateOutlet]="loadMoreButtonTemplateRef"
                        [ngTemplateOutletContext]="{
                            index: loadMoreButtonIndex
                        }"
                    >
                    </ng-template>
                    <ng-container *ngIf="!loadMoreButtonTemplateRef">
                        Load more
                    </ng-container>
                </span>
            </div>
        </li>
    `
    }),
    __metadata("design:paramtypes", [ExpandStateService,
        LoadingNotificationService,
        IndexBuilderService,
        TreeViewLookupService,
        NavigationService,
        NodeChildrenService,
        DataChangeNotificationService,
        ChangeDetectorRef])
], TreeViewGroupComponent);

const indexChecked = (keys, index) => keys.filter(k => k === index).length > 0;
const matchKey = index => k => {
    if (index === k) {
        return true;
    }
    if (!k.split) {
        return false;
    }
    return k.split('_').reduce(({ key, result }, part) => {
        key += part;
        if (index === key || result) {
            return { result: true };
        }
        key += "_";
        return { key, result: false };
    }, { key: "", result: false }).result;
};
const getDescendantsFromLookup = (lookup) => {
    if (!isPresent(lookup) || lookup.children.length === 0) {
        return [];
    }
    let descendants = lookup.children;
    descendants.forEach(child => descendants = descendants.concat(getDescendantsFromLookup(child)));
    return descendants;
};
/**
 * A directive which manages the in-memory checked state of the TreeView node
 * ([see example]({% slug checkboxes_treeview %})).
 */
let CheckDirective = class CheckDirective {
    constructor(treeView, zone) {
        this.treeView = treeView;
        this.zone = zone;
        /**
         * Fires when the `checkedKeys` collection was updated.
         */
        this.checkedKeysChange = new EventEmitter();
        this.subscriptions = new Subscription();
        this.checkActions = {
            'multiple': (e) => this.checkMultiple(e),
            'single': (e) => this.checkSingle(e)
        };
        this._checkedKeys = [];
        this.subscriptions.add(this.treeView.checkedChange
            .subscribe((e) => this.check(e)));
        let expandedItems = [];
        this.subscriptions.add(this.treeView.childrenLoaded
            .pipe(filter(() => this.options.checkChildren), tap(item => expandedItems.push(item)), switchMap(() => this.zone.onStable.pipe(take(1))))
            .subscribe(() => this.addCheckedItemsChildren(expandedItems)));
        this.treeView.isChecked = this.isItemChecked.bind(this);
    }
    /**
     * @hidden
     */
    set isChecked(value) {
        this.treeView.isChecked = value;
    }
    /**
     * Defines the collection that will store the checked keys
     * ([see example]({% slug checkboxes_treeview %})).
     */
    get checkedKeys() {
        return this._checkedKeys;
    }
    set checkedKeys(keys) {
        this._checkedKeys = keys;
    }
    get options() {
        const defaultOptions = {
            checkChildren: true,
            checkParents: true,
            enabled: true,
            mode: "multiple"
        };
        if (!isPresent(this.checkable) || typeof this.checkable === 'string') {
            return defaultOptions;
        }
        const isBoolean = typeof this.checkable === 'boolean';
        const checkSettings = isBoolean
            ? { enabled: this.checkable }
            : this.checkable;
        return Object.assign(defaultOptions, checkSettings);
    }
    ngOnChanges(changes) {
        if (changes.checkable) {
            this.treeView.checkboxes = this.options.enabled;
            this.toggleCheckOnClick();
        }
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.unsubscribeClick();
    }
    isItemChecked(dataItem, index) {
        if (!this.checkKey) {
            return this.isIndexChecked(index);
        }
        const keyIndex = this.checkedKeys.indexOf(this.itemKey({ dataItem, index }));
        return keyIndex > -1 ? 'checked' : 'none';
    }
    isIndexChecked(index) {
        const checkedKeys = this.checkedKeys.filter(matchKey(index));
        if (indexChecked(checkedKeys, index)) {
            return 'checked';
        }
        const { mode, checkParents } = this.options;
        if (mode === 'multiple' && checkParents && checkedKeys.length) {
            return 'indeterminate';
        }
        return 'none';
    }
    itemKey(item) {
        if (!isPresent(this.checkKey)) {
            return item.index;
        }
        if (typeof this.checkKey === "string" && isPresent(item.dataItem)) {
            return item.dataItem[this.checkKey];
        }
        if (typeof this.checkKey === "function") {
            return this.checkKey(item);
        }
    }
    check(e) {
        const { enabled, mode } = this.options;
        const performSelection = this.checkActions[mode] || noop;
        if (!enabled) {
            return;
        }
        performSelection(e);
    }
    checkSingle(node) {
        const key = this.itemKey(node.item);
        this.checkedKeys = this.checkedKeys[0] !== key ? [key] : [];
        this.notify();
    }
    checkMultiple(node) {
        this.checkNode(node);
        if (this.options.checkParents) {
            this.checkParents(node.parent);
        }
        this.notify();
    }
    toggleCheckOnClick() {
        this.unsubscribeClick();
        if (this.options.checkOnClick) {
            this.clickSubscription = this.treeView.nodeClick.subscribe(args => {
                if (args.type === 'click') {
                    const lookup = this.treeView.itemLookup(args.item.index);
                    this.check(lookup);
                }
            });
        }
    }
    unsubscribeClick() {
        if (this.clickSubscription) {
            this.clickSubscription.unsubscribe();
            this.clickSubscription = null;
        }
    }
    checkNode(node) {
        if (!isPresent(node.item.dataItem) || this.treeView.isDisabledNode(node)) {
            return;
        }
        const currentKey = this.itemKey(node.item);
        if (!isPresent(currentKey)) {
            return;
        }
        const checkedKeys = new Set(this.checkedKeys);
        const pendingCheck = [currentKey];
        if (this.options.checkChildren) {
            const descendants = getDescendantsFromLookup(node)
                .filter(lookup => isPresent(lookup.item.dataItem) && !this.treeView.isDisabledNode(lookup))
                .map(({ item }) => this.itemKey(item));
            pendingCheck.push(...descendants);
        }
        const shouldCheck = !checkedKeys.has(currentKey);
        pendingCheck.forEach(key => {
            if (shouldCheck) {
                checkedKeys.add(key);
            }
            else {
                checkedKeys.delete(key);
            }
        });
        this.checkedKeys = Array.from(checkedKeys);
    }
    checkParents(parent) {
        if (!isPresent(parent)) {
            return;
        }
        const checkedKeys = new Set(this.checkedKeys);
        let currentParent = parent;
        while (currentParent) {
            const parentKey = this.itemKey(currentParent.item);
            const allChildrenSelected = currentParent.children.every(item => checkedKeys.has(this.itemKey(item)));
            if (allChildrenSelected) {
                checkedKeys.add(parentKey);
            }
            else {
                checkedKeys.delete(parentKey);
            }
            currentParent = currentParent.parent;
        }
        this.checkedKeys = Array.from(checkedKeys);
    }
    notify() {
        this.checkedKeysChange.emit(this.checkedKeys.slice());
    }
    addCheckedItemsChildren(lookups) {
        if (!isPresent(lookups) || lookups.length === 0) {
            return;
        }
        const initiallyCheckedItemsCount = this.checkedKeys.length;
        const checkedKeys = new Set(this.checkedKeys);
        lookups.forEach(lookup => {
            const itemKey = this.itemKey(lookup.item);
            if (!checkedKeys.has(itemKey)) {
                return;
            }
            lookup.children.forEach(item => isPresent(item.dataItem) && checkedKeys.add(this.itemKey(item)));
        });
        const hasNewlyCheckedItems = initiallyCheckedItemsCount !== checkedKeys.size;
        if (hasNewlyCheckedItems) {
            this.checkedKeys = Array.from(checkedKeys);
            this.zone.run(() => this.notify());
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], CheckDirective.prototype, "isChecked", null);
__decorate([
    Input("checkBy"),
    __metadata("design:type", Object)
], CheckDirective.prototype, "checkKey", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], CheckDirective.prototype, "checkedKeys", null);
__decorate([
    Input('kendoTreeViewCheckable'),
    __metadata("design:type", Object)
], CheckDirective.prototype, "checkable", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], CheckDirective.prototype, "checkedKeysChange", void 0);
CheckDirective = __decorate([
    Directive({ selector: '[kendoTreeViewCheckable]' }),
    __metadata("design:paramtypes", [TreeViewComponent,
        NgZone])
], CheckDirective);

/**
 * A directive which manages the disabled in-memory state of the TreeView node
 * ([see example]({% slug disabledstate_treeview %})).
 */
let DisableDirective = class DisableDirective {
    constructor(treeView, cdr) {
        this.treeView = treeView;
        this.cdr = cdr;
        /**
         * Defines the collection that will store the disabled keys.
         */
        this.disabledKeys = [];
        this.treeView.isDisabled = (dataItem, index) => (this.disabledKeys.indexOf(this.itemKey({ dataItem, index })) > -1);
    }
    /**
     * @hidden
     */
    set isDisabled(value) {
        this.treeView.isDisabled = value;
    }
    ngOnChanges(changes = {}) {
        const { disabledKeys } = changes;
        if (disabledKeys && !disabledKeys.firstChange) {
            this.cdr.markForCheck();
        }
    }
    itemKey(e) {
        if (!this.disableKey) {
            return e.index;
        }
        if (typeof this.disableKey === "string") {
            return e.dataItem[this.disableKey];
        }
        if (typeof this.disableKey === "function") {
            return this.disableKey(e);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], DisableDirective.prototype, "isDisabled", null);
__decorate([
    Input("kendoTreeViewDisable"),
    __metadata("design:type", Object)
], DisableDirective.prototype, "disableKey", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], DisableDirective.prototype, "disabledKeys", void 0);
DisableDirective = __decorate([
    Directive({ selector: '[kendoTreeViewDisable]' }),
    __metadata("design:paramtypes", [TreeViewComponent,
        ChangeDetectorRef])
], DisableDirective);

/**
 * A directive which manages the expanded state of the TreeView
 * ([see example]({% slug expandedstate_treeview %})).
 */
let ExpandDirective = class ExpandDirective {
    constructor(treeView) {
        this.treeView = treeView;
        /**
         * Fires when the `expandedKeys` collection was updated.
         */
        this.expandedKeysChange = new EventEmitter();
        this.subscriptions = new Subscription();
        this._expandedKeys = [];
        this.subscriptions.add(merge(this.treeView.expand.pipe(map(e => (Object.assign({ expand: true }, e)))), this.treeView.collapse.pipe(map(e => (Object.assign({ expand: false }, e))))).subscribe(this.toggleExpand.bind(this)));
        this.treeView.isExpanded = (dataItem, index) => this.expandedKeys.indexOf(this.itemKey({ dataItem, index })) > -1;
    }
    /**
     * @hidden
     */
    set isExpanded(value) {
        this.treeView.isExpanded = value;
    }
    /**
     * Defines the collection that will store the expanded keys.
     */
    get expandedKeys() {
        return this._expandedKeys;
    }
    set expandedKeys(keys) {
        this._expandedKeys = keys;
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    itemKey(e) {
        if (this.expandKey) {
            if (typeof this.expandKey === "string") {
                return e.dataItem[this.expandKey];
            }
            if (typeof this.expandKey === "function") {
                return this.expandKey(e);
            }
        }
        return e.index;
    }
    toggleExpand({ index, dataItem, expand }) {
        const item = this.itemKey({ index, dataItem });
        const idx = this.expandedKeys.indexOf(item);
        let notify = false;
        if (idx > -1 && !expand) {
            this.expandedKeys.splice(idx, 1);
            notify = true;
        }
        else if (idx === -1 && expand) {
            this.expandedKeys.push(item);
            notify = true;
        }
        if (notify) {
            this.expandedKeysChange.emit(this.expandedKeys);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], ExpandDirective.prototype, "isExpanded", null);
__decorate([
    Input("expandBy"),
    __metadata("design:type", Object)
], ExpandDirective.prototype, "expandKey", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ExpandDirective.prototype, "expandedKeysChange", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], ExpandDirective.prototype, "expandedKeys", null);
ExpandDirective = __decorate([
    Directive({ selector: '[kendoTreeViewExpandable]' }),
    __metadata("design:paramtypes", [ExpandableComponent])
], ExpandDirective);

/**
 * A directive which manages the in-memory selection state of the TreeView node
 * ([see example]({% slug selection_treeview %})).
 */
let SelectDirective = class SelectDirective {
    constructor(treeView) {
        this.treeView = treeView;
        /**
         * Fires when the `selectedKeys` collection was updated.
         */
        this.selectedKeysChange = new EventEmitter();
        this.subscriptions = new Subscription();
        this.selectActions = {
            'multiple': (e) => this.selectMultiple(e),
            'single': (e) => this.selectSingle(e)
        };
        this._selectedKeys = [];
        this.subscriptions.add(this.treeView.selectionChange.subscribe(this.select.bind(this)));
        this.treeView.isSelected = (dataItem, index) => (this.selectedKeys.indexOf(this.itemKey({ dataItem, index })) > -1);
    }
    /**
     * @hidden
     */
    set isSelected(value) {
        this.treeView.isSelected = value;
    }
    /**
     * Defines the collection that will store the selected keys
     * ([see example]({% slug selection_treeview %}#toc-selection-modes)).
     */
    get selectedKeys() {
        return this._selectedKeys;
    }
    set selectedKeys(keys) {
        this._selectedKeys = keys;
    }
    get getAriaMultiselectable() {
        return this.options.mode === 'multiple';
    }
    get options() {
        const defaultOptions = {
            enabled: true,
            mode: 'single'
        };
        if (!isPresent(this.selection) || typeof this.selection === 'string') {
            return defaultOptions;
        }
        const isBoolean = typeof this.selection === 'boolean';
        const selectionSettings = isBoolean ? { enabled: this.selection } : this.selection;
        return Object.assign(defaultOptions, selectionSettings);
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    itemKey(e) {
        if (!this.selectKey) {
            return e.index;
        }
        if (typeof this.selectKey === 'string') {
            return e.dataItem[this.selectKey];
        }
        if (typeof this.selectKey === 'function') {
            return this.selectKey(e);
        }
    }
    select(e) {
        const { enabled, mode } = this.options;
        const performSelection = this.selectActions[mode] || noop;
        if (!enabled) {
            return;
        }
        performSelection(e);
    }
    selectSingle(node) {
        const key = this.itemKey(node);
        if (this.selectedKeys[0] === key) {
            return;
        }
        this.selectedKeys = [key];
        this.notify();
    }
    selectMultiple(node) {
        const key = this.itemKey(node);
        const idx = this.selectedKeys.indexOf(key);
        const isSelected = idx > -1;
        if (!isPresent(key)) {
            return;
        }
        if (isSelected) {
            this.selectedKeys.splice(idx, 1);
        }
        else {
            this.selectedKeys.push(key);
        }
        this.notify();
    }
    notify() {
        this.selectedKeysChange.emit(this.selectedKeys.slice());
    }
};
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], SelectDirective.prototype, "isSelected", null);
__decorate([
    Input('selectBy'),
    __metadata("design:type", Object)
], SelectDirective.prototype, "selectKey", void 0);
__decorate([
    Input('kendoTreeViewSelectable'),
    __metadata("design:type", Object)
], SelectDirective.prototype, "selection", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], SelectDirective.prototype, "selectedKeys", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SelectDirective.prototype, "selectedKeysChange", void 0);
__decorate([
    HostBinding('attr.aria-multiselectable'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SelectDirective.prototype, "getAriaMultiselectable", null);
SelectDirective = __decorate([
    Directive({ selector: '[kendoTreeViewSelectable]' }),
    __metadata("design:paramtypes", [TreeViewComponent])
], SelectDirective);

/**
 * Describes the attempted drop action during dragging.
 * Passed as `action` value to the [`kendoTreeViewDragClueTemplate`]({% slug api_treeview_dragcluetemplatedirective %}) directive.
 * By default, this value defines the rendered icon in the drag clue.
 */
var DropAction;
(function (DropAction) {
    DropAction[DropAction["Add"] = 0] = "Add";
    DropAction[DropAction["InsertTop"] = 1] = "InsertTop";
    DropAction[DropAction["InsertBottom"] = 2] = "InsertBottom";
    DropAction[DropAction["InsertMiddle"] = 3] = "InsertMiddle";
    DropAction[DropAction["Invalid"] = 4] = "Invalid";
})(DropAction || (DropAction = {}));

/**
 * Describes where the dragged item is dropped relative to the drop target item.
 */
var DropPosition;
(function (DropPosition) {
    DropPosition[DropPosition["Over"] = 0] = "Over";
    DropPosition[DropPosition["Before"] = 1] = "Before";
    DropPosition[DropPosition["After"] = 2] = "After";
})(DropPosition || (DropPosition = {}));

/**
 * @hidden
 */
class PreventableEvent {
    constructor() {
        this.prevented = false;
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * If the event is prevented by any of its subscribers, returns `true`.
     *
     * @returns `true` if the default action was prevented. Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * Arguments for the TreeView [`nodeDrop`]({% slug api_treeview_treeviewcomponent %}#toc-nodedrop) event.
 */
class TreeItemDropEvent extends PreventableEvent {
    /**
     * @hidden
     */
    constructor(initializer, originalEvent) {
        super();
        /**
         * @hidden
         */
        this.isValid = true;
        Object.assign(this, initializer);
        this.originalEvent = originalEvent;
    }
    /**
     * Specifies if the drop action should be marked as valid.
     * If set to `false`, the [`addItem`]({% slug api_treeview_treeviewcomponent %}#toc-additem) and
     * [`removeItem`]({% slug api_treeview_treeviewcomponent %}#toc-removeitem) events will not be fired and the drag clue
     * will be animated back to the source item to indicate the action is marked as invalid.
     */
    setValid(isValid) {
        this.isValid = isValid;
    }
}

/**
 * Arguments for the TreeView [`nodeDragStart`]({% slug api_treeview_treeviewcomponent %}#toc-nodedragstart) event.
 */
class TreeItemDragStartEvent extends PreventableEvent {
    /**
     * @hidden
     */
    constructor(initializer) {
        super();
        Object.assign(this, initializer);
    }
}

/**
 * Arguments for the TreeView [`nodeDrag`]({% slug api_treeview_treeviewcomponent %}#toc-nodedrag) and
 * [`nodeDragEnd`]({% slug api_treeview_treeviewcomponent %}#toc-nodedragend) events.
 */
class TreeItemDragEvent {
}

const ɵ0$3 = () => {
    if (!(isDocumentAvailable() && isPresent(document.body))) {
        return false;
    }
    const top = 10;
    const parent = document.createElement("div");
    parent.style.transform = "matrix(10, 0, 0, 10, 0, 0)";
    parent.innerHTML = `<div style="position: fixed; top: ${top}px;">child</div>`;
    document.body.appendChild(parent);
    const isDifferent = parent.children[0].getBoundingClientRect().top !== top;
    document.body.removeChild(parent);
    return isDifferent;
};
/**
 * Checks if the browser supports relative stacking context.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
const hasRelativeStackingContext = memoize(ɵ0$3);
/**
 * Stores the result of the passed function's first invokation and returns it instead of invoking it again afterwards.
 */
function memoize(fn) {
    let result;
    let called = false;
    return (...args) => {
        if (called) {
            return result;
        }
        result = fn(...args);
        called = true;
        return result;
    };
}
/**
 * @hidden
 *
 * Gets the offset of the parent element if the latter has the `transform` CSS prop applied.
 * Transformed parents create new stacking context and the `fixed` children must be position based on the transformed parent.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 *
 * If no parent container is `transform`-ed the function will return `{ left: 0, top: 0 }`;
 */
const getContainerOffset = (element) => {
    if (!(element && hasRelativeStackingContext())) {
        return { left: 0, top: 0 };
    }
    let offsetParent = element.parentElement;
    while (offsetParent) {
        if (window.getComputedStyle(offsetParent).transform !== 'none') {
            break;
        }
        offsetParent = offsetParent.parentElement;
    }
    if (offsetParent) {
        const rect = offsetParent.getBoundingClientRect();
        return {
            left: rect.left - offsetParent.scrollLeft,
            top: rect.top - offsetParent.scrollTop
        };
    }
    return { left: 0, top: 0 };
};
/**
 * @hidden
 */
const getDropAction = (dropPosition, dropTarget) => {
    if (!(isPresent(dropPosition) && isPresent(dropTarget))) {
        return DropAction.Invalid;
    }
    switch (dropPosition) {
        case DropPosition.Over:
            return DropAction.Add;
        case DropPosition.Before:
            return isPresent(closestNode(dropTarget).previousElementSibling) ? DropAction.InsertMiddle : DropAction.InsertTop;
        case DropPosition.After:
            return isPresent(closestNode(dropTarget).nextElementSibling) ? DropAction.InsertMiddle : DropAction.InsertBottom;
        default:
            return DropAction.Invalid;
    }
};
/**
 * @hidden
 */
const getDropPosition = (draggedItem, target, clientY, targetTreeView, containerOffset) => {
    if (!(isPresent(draggedItem) && isPresent(target) && isPresent(targetTreeView) && isPresent(containerOffset))) {
        return;
    }
    // the .k-mid element starts just after the checkbox/expand arrow and stretches till the end of the treeview on the right
    const item = closestWithMatch(target, '.k-mid');
    if (!isPresent(item)) {
        return;
    }
    // the content element (.k-in:not(.k-treeview-load-more-button)) holds just the treeview item text
    const content = getContentElement(item);
    const targetChildOfDraggedItem = hasParent(item, closestNode(draggedItem));
    if (!isPresent(content) || (content === draggedItem) || targetChildOfDraggedItem) {
        return;
    }
    const itemViewPortCoords = content.getBoundingClientRect();
    /*
        if the user is hovering a treeview item, split the item height into four parts:
            - dropping into the top quarter should insert the dragged item before the drop target
            - dropping into the bottom quarter should insert the dragged item after the drop target
            - dropping into the second or third quarter should add the item as child node of the drop target

        if the user is NOT hovering a treeview item (he's dragging somewhere on the right), split the item height to just two parts:
            - dropping should insert before or after
    */
    const itemDivisionHeight = itemViewPortCoords.height / (isContent(target) ? 4 : 2);
    // clear any possible container offset created by parent elements with `transform` css property set
    const pointerPosition = clientY - containerOffset.top;
    const itemTop = itemViewPortCoords.top - containerOffset.top;
    if (pointerPosition < itemTop + itemDivisionHeight) {
        return DropPosition.Before;
    }
    if (pointerPosition >= itemTop + itemViewPortCoords.height - itemDivisionHeight) {
        return DropPosition.After;
    }
    return DropPosition.Over;
};
/**
 * @hidden
 */
const treeItemFromEventTarget = (treeView, dropTarget) => {
    if (!(isPresent(treeView) && isPresent(dropTarget))) {
        return null;
    }
    const node = closestNode(dropTarget);
    const index = nodeId(node);
    const lookup = treeView.itemLookup(index);
    if (!(isPresent(lookup) && isPresent(lookup.item.dataItem))) {
        return null;
    }
    return lookup;
};
/**
 * @hidden
 *
 * Emits `collapse` on the specified TreeView node if the latter is left empty after its last child node was dragged out.
 */
const collapseEmptyParent = (parent, parentNodes, treeview) => {
    if (isPresent(parent) && parentNodes.length === 0 && treeview.isExpanded(parent.item.dataItem, parent.item.index)) {
        treeview.collapseNode(parent.item.dataItem, parent.item.index);
    }
};
/**
 * @hidden
 *
 * Expands the node if it's dropped into and it's not yet expanded.
 */
const expandDropTarget = (dropTarget, treeView) => {
    if (!treeView.isExpanded(dropTarget.item.dataItem, dropTarget.item.index)) {
        treeView.expandNode(dropTarget.item.dataItem, dropTarget.item.index);
    }
};
/**
 * @hidden
 *
 * Extracts the event target from the viewport coords. Required for touch devices
 * where the `event.target` of a `pointermove` event is always the initially dragged item.
 */
const getDropTarget = (event) => {
    if (!(isDocumentAvailable() && isPresent(document.elementFromPoint))) {
        return event.target;
    }
    return document.elementFromPoint(event.clientX, event.clientY);
};

/**
 * @hidden
 */
const copyPageSize = (treeview, source, target) => {
    if (!isPresent(treeview.loadMoreService)) {
        return;
    }
    const sourceGroupSize = treeview.getNodePageSize(source);
    treeview.setNodePageSize(target, sourceGroupSize);
};
/**
 * @hidden
 */
const incrementPageSize = (treeview, dataItem) => {
    if (!isPresent(treeview.loadMoreService)) {
        return;
    }
    const currentPageSize = treeview.getNodePageSize(dataItem);
    treeview.setNodePageSize(dataItem, currentPageSize + 1);
};
/**
 * @hidden
 */
const decrementPageSize = (treeview, dataItem) => {
    if (!isPresent(treeview.loadMoreService)) {
        return;
    }
    const currentPageSize = treeview.getNodePageSize(dataItem);
    treeview.setNodePageSize(dataItem, currentPageSize - 1);
};

/**
 * @hidden
 */
class HierarchyEditingService {
    constructor(hierarchyBinding) {
        this.hierarchyBinding = hierarchyBinding;
    }
    add({ sourceItem, destinationItem, dropPosition, destinationTree }) {
        // shallow clone the item as not to mistake it for its 'older' version when the remove handler kicks in to splice the item at its old position
        const clonedSourceDataItem = Object.assign({}, getDataItem(sourceItem));
        if (dropPosition === DropPosition.Over) {
            // expand the item that was dropped into
            expandDropTarget(destinationItem, destinationTree);
            const destinationChildren = this.childrenFor(getDataItem(destinationItem));
            // add the moved node just before the load more button if load more is enabled
            const targetIndex = isPresent(destinationTree.loadMoreService) ?
                destinationTree.loadMoreService.getGroupSize(getDataItem(destinationItem)) :
                destinationChildren.length;
            destinationChildren.splice(targetIndex, 0, clonedSourceDataItem);
            setter(this.hierarchyBinding.childrenField)(getDataItem(destinationItem), destinationChildren);
        }
        else {
            const destinationParentNodes = this.getParentNodes(destinationItem, destinationTree);
            const shiftIndex = dropPosition === DropPosition.After ? 1 : 0;
            const targetIndex = destinationParentNodes.indexOf(getDataItem(destinationItem)) + shiftIndex;
            destinationParentNodes.splice(targetIndex, 0, clonedSourceDataItem);
        }
        // increment the parent page size => an item is moved into it
        const updatedParent = dropPosition === DropPosition.Over ? getDataItem(destinationItem) : getDataItem(destinationItem.parent);
        incrementPageSize(destinationTree, updatedParent);
        // the page sizes are stored by data-item reference => copy the old item ref page size to the new item reference
        copyPageSize(destinationTree, getDataItem(sourceItem), clonedSourceDataItem);
    }
    remove({ sourceItem, sourceTree }) {
        const sourceParentNodes = this.getParentNodes(sourceItem, sourceTree);
        const sourceItemIndex = sourceParentNodes.indexOf(getDataItem(sourceItem));
        sourceParentNodes.splice(sourceItemIndex, 1);
        // emit collapse for the parent node if its last child node was spliced
        collapseEmptyParent(sourceItem.parent, sourceParentNodes, sourceTree);
        // decrement source item parent page size => an item has been removed from it
        decrementPageSize(sourceTree, getDataItem(sourceItem.parent));
    }
    getParentNodes(node, treeView) {
        return isPresent(node.parent) ?
            this.childrenFor(getDataItem(node.parent)) :
            treeView.nodes;
    }
    childrenFor(dataItem) {
        return getter(this.hierarchyBinding.childrenField)(dataItem) || [];
    }
}

/**
 * @hidden
 */
const defaultTreeviewFilterSettings = { operator: 'contains', ignoreCase: true };

/**
 * @hidden
 */
let DragClueComponent = class DragClueComponent {
    constructor(cdr) {
        this.cdr = cdr;
        this.hostClasses = true;
        this.posistionStyle = 'fixed';
    }
    get statusIconClass() {
        switch (this.action) {
            case DropAction.Add: return 'k-i-plus';
            case DropAction.InsertTop: return 'k-i-insert-up';
            case DropAction.InsertBottom: return 'k-i-insert-down';
            case DropAction.InsertMiddle: return 'k-i-insert-middle';
            case DropAction.Invalid:
            default: return 'k-i-cancel';
        }
    }
    // exposed as a public method that can be called from outside as the component uses `OnPush` strategy
    detectChanges() {
        this.cdr.detectChanges();
    }
};
__decorate([
    HostBinding('class.k-header'),
    HostBinding('class.k-drag-clue'),
    __metadata("design:type", Boolean)
], DragClueComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('style.position'),
    __metadata("design:type", String)
], DragClueComponent.prototype, "posistionStyle", void 0);
DragClueComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-treeview-drag-clue',
        template: `
        <ng-container *ngIf="!template">
            <span class="k-icon {{statusIconClass}} k-drag-status"></span>
            <span>{{text}}</span>
        </ng-container>

        <ng-template
            *ngIf="template"
            [ngTemplateOutlet]="template"
            [ngTemplateOutletContext]="{
                text: text,
                action: action,
                sourceItem: sourceItem,
                destinationItem: destinationItem
            }"
        >
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef])
], DragClueComponent);

/**
 * @hidden
 */
class DragAndDropAssetService {
    get componentRef() {
        if (!isPresent(this._componentRef)) {
            throw new Error('The `initalize` method must be called before calling other service methods.');
        }
        return this._componentRef;
    }
    set componentRef(componentRef) {
        this._componentRef = componentRef;
    }
    get element() {
        return this.componentRef.location.nativeElement;
    }
    ngOnDestroy() {
        if (!isPresent(this._componentRef)) {
            return;
        }
        this.element.parentElement.removeChild(this.element);
        this.componentRef.destroy();
        this.componentRef = null;
    }
    show() {
        this.element.style.display = '';
    }
    hide() {
        this.element.style.display = 'none';
    }
    move(left, top, offset = 0) {
        this.element.style.left = `${left + offset}px`;
        this.element.style.top = `${top + offset}px`;
    }
}

/**
 * @hidden
 */
const CLUE_OFFSET = 10;
/**
 * @hidden
 */
const RETURN_ANIMATION_DURATION = 200;
/**
 * @hidden
 */
let DragClueService = class DragClueService extends DragAndDropAssetService {
    constructor(componentFactoryResolver) {
        super();
        this.componentFactoryResolver = componentFactoryResolver;
    }
    initialize(container, template) {
        if (isPresent(this._componentRef)) {
            this.ngOnDestroy();
        }
        const clueComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DragClueComponent);
        this.componentRef = container.createComponent(clueComponentFactory);
        this.hide();
        this.componentRef.instance.template = template;
        this.componentRef.changeDetectorRef.detectChanges();
    }
    ngOnDestroy() {
        this.cancelReturnAnimation();
        super.ngOnDestroy();
    }
    move(left, top) {
        super.move(left, top, CLUE_OFFSET);
    }
    animateDragClueToElementPosition(target) {
        if (!(isPresent(target) && isPresent(this.element.animate))) {
            this.hide();
            return;
        }
        const targetElementViewPortCoords = target.getBoundingClientRect();
        const clueElementViewPortCoords = this.element.getBoundingClientRect();
        this.returnAnimation = this.element.animate([
            { transform: 'translate(0, 0)' },
            { transform: `translate(${targetElementViewPortCoords.left - clueElementViewPortCoords.left}px, ${targetElementViewPortCoords.top - clueElementViewPortCoords.top}px)` }
        ], RETURN_ANIMATION_DURATION);
        this.returnAnimation.onfinish = () => this.hide();
    }
    cancelReturnAnimation() {
        if (!isPresent(this.returnAnimation)) {
            return;
        }
        this.returnAnimation.cancel();
        this.returnAnimation = null;
    }
    updateDragClueData(action, sourceItem, destinationItem) {
        const dragClue = this.componentRef.instance;
        if (action === dragClue.action && dataItemsEqual(sourceItem, dragClue.sourceItem) && dataItemsEqual(destinationItem, dragClue.destinationItem)) {
            return;
        }
        dragClue.action = action;
        dragClue.sourceItem = sourceItem;
        dragClue.destinationItem = destinationItem;
        dragClue.detectChanges();
    }
    updateText(text) {
        if (text === this.componentRef.instance.text) {
            return;
        }
        this.componentRef.instance.text = text;
        this.componentRef.instance.detectChanges();
    }
};
DragClueService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [ComponentFactoryResolver])
], DragClueService);

/**
 * @hidden
 */
let DropHintComponent = class DropHintComponent {
    /**
     * @hidden
     */
    constructor() {
        this.hostClass = true;
        this.position = 'fixed';
        this.pointerEvents = 'none';
    }
};
__decorate([
    HostBinding('class.k-drop-hint-container'),
    __metadata("design:type", Boolean)
], DropHintComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('style.position'),
    __metadata("design:type", String)
], DropHintComponent.prototype, "position", void 0);
__decorate([
    HostBinding('style.pointer-events'),
    __metadata("design:type", String)
], DropHintComponent.prototype, "pointerEvents", void 0);
DropHintComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-treeview-drop-hint',
        template: `
        <div
            *ngIf="!template"
            class="k-drop-hint k-drop-hint-h"
        >
            <div class='k-drop-hint-start'></div>
            <div class='k-drop-hint-line'></div>
        </div>

        <ng-template
            *ngIf="template"
            [ngTemplateOutlet]="template"
        >
        <ng-template>
    `
    })
], DropHintComponent);

/**
 * @hidden
 */
let DropHintService = class DropHintService extends DragAndDropAssetService {
    constructor(componentFactoryResolver) {
        super();
        this.componentFactoryResolver = componentFactoryResolver;
    }
    initialize(container, template) {
        if (isPresent(this._componentRef)) {
            this.ngOnDestroy();
        }
        const hintComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DropHintComponent);
        this.componentRef = container.createComponent(hintComponentFactory);
        this.hide();
        this.componentRef.instance.template = template;
        this.componentRef.changeDetectorRef.detectChanges();
    }
};
DropHintService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [ComponentFactoryResolver])
], DropHintService);

/**
 * Represents the template for the TreeView drag clue when an item is dragged. To define the clue template,
 * nest an `<ng-template>` tag with the `kendoTreeViewDragClueTemplate` directive inside a `<kendo-treeview>` tag
 * ([see example]({% slug draganddrop_treeview %}#toc-templates)).
 *
 *
 * The text, attempted drop action, source item and destination item are available as context variables in the template:
 *
 *
 * - `let-text="text"` (`string`)
 * - `let-action="action"` ([`DropAction`]({% slug api_treeview_dropaction %}))
 * - `let-sourceItem="sourceItem"` ([`TreeItemLookup`]({% slug api_treeview_treeitemlookup %}))
 * - `let-destinationItem="destinationItem"` ([`TreeItemLookup`]({% slug api_treeview_treeitemlookup %}))
 */
let DragClueTemplateDirective = class DragClueTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
DragClueTemplateDirective = __decorate([
    Directive({
        selector: '[kendoTreeViewDragClueTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], DragClueTemplateDirective);

/**
 * Represents the template for the TreeView drop hint when an item is dragged. To define the hint template,
 * nest an `<ng-template>` tag with the `kendoTreeViewDropHintTemplate` directive inside a `<kendo-treeview>` tag
 * ([see example]({% slug draganddrop_treeview %}#toc-templates)).
 */
let DropHintTemplateDirective = class DropHintTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
DropHintTemplateDirective = __decorate([
    Directive({
        selector: '[kendoTreeViewDropHintTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], DropHintTemplateDirective);

/**
 * A directive which enables the dragging and dropping items inside the current TreeView or between multiple linked TreeView component instances
 * ([see example]({% slug draganddrop_treeview %})).
 *
 * Triggers the [`nodeDragStart`]({% slug api_treeview_treeviewcomponent %}#toc-nodedragstart),
 * [`nodeDrag`]({% slug api_treeview_treeviewcomponent %}#toc-nodedrag),
 * [`nodeDrop`]({% slug api_treeview_treeviewcomponent %}#toc-nodedrop),
 * [`nodeDragEnd`]({% slug api_treeview_treeviewcomponent %}#toc-nodedragend),
 * [`addItem`]({% slug api_treeview_treeviewcomponent %}#toc-additem) and
 * [`removeItem`]({% slug api_treeview_treeviewcomponent %}#toc-removeitem)
 * events when the corresponding actions occur on the respective TreeView instance.
 */
let DragAndDropDirective = class DragAndDropDirective {
    constructor(element, zone, treeview, dragClueService, dropHintService) {
        this.element = element;
        this.zone = zone;
        this.treeview = treeview;
        this.dragClueService = dragClueService;
        this.dropHintService = dropHintService;
        /**
         * Specifies whether the `removeItem` event will be fired after an item is dropped when the `ctrl` key is pressed.
         * If enabled, the `removeItem` event will not be fired on the source TreeView
         * ([see example]({% slug draganddrop_treeview %}#toc-multiple-treeviews)).
         *
         * @default false
         */
        this.allowCopy = false;
        /**
         * Specifes the TreeViewComponent instances into which dragged items from the current TreeViewComponent can be dropped
         * ([see example]({% slug draganddrop_treeview %}#toc-multiple-treeviews)).
         */
        this.dropZoneTreeViews = [];
        /**
         * @hidden
         */
        this.userSelectStyle = 'none';
        /**
         * Describes the offset of the parent element if the latter has the `transform` CSS prop applied.
         * Transformed parents create new stacking context and the fixed children must be position based on the transformed parent.
         * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
         */
        this.containerOffset = { top: 0, left: 0 };
        this.treeview.touchActions = false;
    }
    ngAfterContentInit() {
        this.initalizeDraggable();
        this.dragClueService.initialize(this.treeview.assetsContainer, this.dragClueTemplate && this.dragClueTemplate.templateRef);
        this.dropHintService.initialize(this.treeview.assetsContainer, this.dropHintTemplate && this.dropHintTemplate.templateRef);
    }
    ngOnDestroy() {
        this.draggable.destroy();
    }
    /**
     * @hidden
     */
    handlePress({ originalEvent }) {
        if (!isContent(originalEvent.target)) {
            return;
        }
        const dropTarget = getDropTarget(originalEvent);
        if (hasObservers(this.treeview.nodeDragStart)) {
            const dragStartEvent = this.zone.run(() => this.notifyDragStart(originalEvent, dropTarget));
            if (dragStartEvent.isDefaultPrevented()) {
                return;
            }
        }
        // store the drag target on press, show it only when it's actually dragged
        this.draggedItem = closestWithMatch(originalEvent.target, '.k-in');
        this.dragClueService.cancelReturnAnimation();
        this.dragClueService.updateText(this.draggedItem.innerText);
        this.containerOffset = getContainerOffset(this.draggedItem);
    }
    /**
     * @hidden
     */
    handleDrag({ originalEvent, clientX, clientY }) {
        if (!isPresent(this.draggedItem)) {
            return;
        }
        const dropTarget = getDropTarget(originalEvent);
        if (hasObservers(this.treeview.nodeDrag)) {
            this.zone.run(() => this.notifyDrag(originalEvent, dropTarget));
        }
        const targetTreeView = this.getTargetTreeView(dropTarget);
        const dropPosition = getDropPosition(this.draggedItem, dropTarget, clientY, targetTreeView, this.containerOffset);
        const dropHintAnchor = closestWithMatch(dropTarget, '.k-mid');
        this.updateDropHintState(dropPosition, dropHintAnchor);
        const dropAction = getDropAction(dropPosition, dropTarget);
        const sourceItem = treeItemFromEventTarget(this.treeview, this.draggedItem);
        const destinationItem = treeItemFromEventTarget(targetTreeView, dropTarget);
        this.updateDragClueState(dropAction, clientX, clientY, sourceItem, destinationItem);
    }
    /**
     * @hidden
     */
    handleRelease({ originalEvent, clientY }) {
        if (!isPresent(this.draggedItem)) {
            return;
        }
        const dropTarget = getDropTarget(originalEvent);
        const sourceTree = this.treeview;
        const destinationTree = this.getTargetTreeView(dropTarget);
        const dropPosition = getDropPosition(this.draggedItem, dropTarget, clientY, this.getTargetTreeView(dropTarget), this.containerOffset);
        const sourceItem = treeItemFromEventTarget(sourceTree, this.draggedItem);
        const destinationItem = treeItemFromEventTarget(destinationTree, dropTarget);
        if (isPresent(destinationItem) && isPresent(dropPosition)) {
            this.zone.run(() => this.notifyDrop({ sourceItem, destinationItem, dropPosition, sourceTree, destinationTree }, originalEvent));
        }
        else {
            this.dragClueService.animateDragClueToElementPosition(this.draggedItem);
        }
        if (hasObservers(this.treeview.nodeDragEnd)) {
            this.zone.run(() => this.notifyDragEnd({ sourceItem, destinationItem, originalEvent }));
        }
        this.dropHintService.hide();
        this.draggedItem = null;
    }
    updateDropHintState(dropPosition, dropHintAnchor) {
        if (!isPresent(dropHintAnchor) || dropPosition === DropPosition.Over || !isPresent(dropPosition)) {
            this.dropHintService.hide();
            return;
        }
        const anchorViewPortCoords = dropHintAnchor.getBoundingClientRect();
        const insertBefore = dropPosition === DropPosition.Before;
        const top = insertBefore ? anchorViewPortCoords.top : (anchorViewPortCoords.top + anchorViewPortCoords.height);
        // clear any possible container offset created by parent elements with `transform` css property set
        this.dropHintService.move(anchorViewPortCoords.left - this.containerOffset.left, top - this.containerOffset.top);
        this.dropHintService.show();
    }
    updateDragClueState(dropAction, clientX, clientY, sourceItem, destinationItem) {
        // clear any possible container offset created by parent elements with `transform` css property set
        this.dragClueService.move(clientX - this.containerOffset.left, clientY - this.containerOffset.top);
        this.dragClueService.updateDragClueData(dropAction, sourceItem, destinationItem);
        this.dragClueService.show();
    }
    initalizeDraggable() {
        this.draggable = new Draggable({
            press: this.handlePress.bind(this),
            drag: this.handleDrag.bind(this),
            release: this.handleRelease.bind(this)
        });
        this.zone.runOutsideAngular(() => this.draggable.bindTo(this.element.nativeElement));
    }
    notifyDragStart(originalEvent, dropTarget) {
        const sourceItem = treeItemFromEventTarget(this.treeview, dropTarget);
        const event = new TreeItemDragStartEvent({ sourceItem, originalEvent });
        this.treeview.nodeDragStart.emit(event);
        return event;
    }
    notifyDrag(originalEvent, dropTarget) {
        const dragEvent = {
            sourceItem: treeItemFromEventTarget(this.treeview, this.draggedItem),
            destinationItem: treeItemFromEventTarget(this.getTargetTreeView(dropTarget), dropTarget),
            originalEvent
        };
        this.treeview.nodeDrag.emit(dragEvent);
    }
    notifyDrop(args, originalEvent) {
        const event = new TreeItemDropEvent(args, originalEvent);
        args.destinationTree.nodeDrop.emit(event);
        // disable the animations on drop and restore them afterwards (if they were initially turned on)
        this.disableAnimationsForNextTick(args.destinationTree);
        if (args.sourceTree !== args.destinationTree) {
            this.disableAnimationsForNextTick(args.sourceTree);
        }
        if (!event.isDefaultPrevented() && event.isValid) {
            this.dragClueService.hide();
            // order matters in a flat data binding scenario (first add, then remove)
            args.destinationTree.addItem.emit(args);
            if (!(originalEvent.ctrlKey && this.allowCopy)) {
                args.sourceTree.removeItem.emit(args);
            }
        }
        else if (event.isDefaultPrevented()) {
            // directly hide the clue if the default is prevented
            this.dragClueService.hide();
        }
        else if (!event.isValid) {
            // animate the clue back to the source item position if marked as invalid
            this.dragClueService.animateDragClueToElementPosition(this.draggedItem);
        }
    }
    notifyDragEnd(dragEndEvent) {
        this.treeview.nodeDragEnd.emit(dragEndEvent);
    }
    getTargetTreeView(dropTarget) {
        const treeViewTagName = this.treeview.element.nativeElement.tagName;
        const targetTreeView = closestWithMatch(dropTarget, treeViewTagName);
        return [this.treeview, ...this.dropZoneTreeViews].find(treeView => isPresent(treeView) && treeView.element.nativeElement === targetTreeView);
    }
    disableAnimationsForNextTick(treeView) {
        // the treeView.animate getter returns `true` when the animations are turned off
        // confusing, but seems on purpose (the `animate` prop sets the value of the @.disabled host-bound attribute)
        if (treeView.animate) {
            return;
        }
        treeView.animate = false;
        this.zone.runOutsideAngular(() => setTimeout(() => treeView.animate = true));
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DragAndDropDirective.prototype, "allowCopy", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], DragAndDropDirective.prototype, "dropZoneTreeViews", void 0);
__decorate([
    ContentChild(DragClueTemplateDirective, { static: false }),
    __metadata("design:type", DragClueTemplateDirective)
], DragAndDropDirective.prototype, "dragClueTemplate", void 0);
__decorate([
    ContentChild(DropHintTemplateDirective, { static: false }),
    __metadata("design:type", DropHintTemplateDirective)
], DragAndDropDirective.prototype, "dropHintTemplate", void 0);
__decorate([
    HostBinding('style.user-select'),
    HostBinding('style.-ms-user-select'),
    __metadata("design:type", String)
], DragAndDropDirective.prototype, "userSelectStyle", void 0);
DragAndDropDirective = __decorate([
    Directive({
        selector: '[kendoTreeViewDragAndDrop]',
        providers: [
            DragClueService,
            DropHintService
        ]
    }),
    __metadata("design:paramtypes", [ElementRef,
        NgZone,
        TreeViewComponent,
        DragClueService,
        DropHintService])
], DragAndDropDirective);

const mapToWrappers = (currentLevelNodes, childrenField) => {
    if (!isArrayWithAtLeastOneItem(currentLevelNodes)) {
        return [];
    }
    return currentLevelNodes.map(node => ({
        dataItem: node,
        children: mapToWrappers(getter(childrenField)(node), childrenField)
    }));
};
/**
 * A directive which encapsulates the retrieval of child nodes.
 */
let HierarchyBindingDirective = class HierarchyBindingDirective {
    constructor(treeView, dragAndDropDirective) {
        this.treeView = treeView;
        this.dragAndDropDirective = dragAndDropDirective;
        /**
         * Stores the unfiltered nodes
         */
        this.filterData = [];
        this.visibleNodes = new Set();
        this._filterSettings = defaultTreeviewFilterSettings;
        const shouldFilter = !isPresent(this.dragAndDropDirective);
        this.treeView.isVisible = shouldFilter ? (node) => this.visibleNodes.has(node) : isVisible;
    }
    /**
     * The field name which holds the data items of the child component.
     */
    set childrenField(value) {
        if (!value) {
            throw new Error("'childrenField' cannot be empty");
        }
        this._childrenField = value;
    }
    /**
     * The nodes which will be displayed by the TreeView.
     */
    set nodes(values) {
        this.filterData = mapToWrappers(values, this.childrenField) || [];
        this.visibleNodes.clear();
        this.updateNodesVisibility(this.filterData);
    }
    /**
     * @hidden
     * A callback which determines whether a TreeView node should be rendered as hidden.
     */
    set isVisible(fn) {
        this.treeView.isVisible = fn;
    }
    /**
     * Applies a filter and changes the visibility of the component's nodes accordingly.
     * To customize the built-in filtering, use the [filterSettings]({% slug api_treeview_hierarchybindingdirective %}#toc-filtersettings) prop.
     */
    set filter(term) {
        this.handleFilterChange(term);
    }
    /**
     * The settings which are applied when performing a filter on the component's data.
     */
    set filterSettings(value) {
        this._filterSettings = Object.assign({}, defaultTreeviewFilterSettings, value);
    }
    get filterSettings() {
        return this._filterSettings;
    }
    /**
     * The field name which holds the data items of the child component.
     */
    get childrenField() {
        return this._childrenField;
    }
    ngOnInit() {
        if (isPresent(this.childrenField)) {
            this.treeView.children = item => of(getter(this.childrenField)(item));
            this.treeView.hasChildren = item => {
                const children = getter(this.childrenField)(item);
                return Boolean(children && children.length);
            };
            this.treeView.editService = new HierarchyEditingService(this);
            this.treeView.filterChange.subscribe(this.handleFilterChange.bind(this));
            if (this.treeView.filter) {
                this.handleFilterChange(this.treeView.filter);
            }
        }
    }
    /**
     * @hidden
     */
    handleFilterChange(term) {
        if (!this.filterData) {
            return;
        }
        const filteredNodes = term ? filterTree(this.filterData, term, this.filterSettings, this.treeView.textField) : this.filterData;
        this.visibleNodes.clear();
        this.updateNodesVisibility(filteredNodes);
    }
    updateNodesVisibility(items) {
        items.forEach((wrapper) => {
            this.visibleNodes.add(wrapper.dataItem);
            if (wrapper.children) {
                this.updateNodesVisibility(wrapper.children);
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], HierarchyBindingDirective.prototype, "childrenField", null);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], HierarchyBindingDirective.prototype, "nodes", null);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], HierarchyBindingDirective.prototype, "isVisible", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], HierarchyBindingDirective.prototype, "filter", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], HierarchyBindingDirective.prototype, "filterSettings", null);
HierarchyBindingDirective = __decorate([
    Directive({ selector: '[kendoTreeViewHierarchyBinding]' }),
    __param(1, Optional()), __param(1, Host()),
    __metadata("design:paramtypes", [DataBoundComponent, DragAndDropDirective])
], HierarchyBindingDirective);

/**
 * @hidden
 */
let LoadingIndicatorDirective = class LoadingIndicatorDirective {
    constructor(expandService, loadingService, cd) {
        this.expandService = expandService;
        this.loadingService = loadingService;
        this.cd = cd;
        this._loading = false;
    }
    get loading() {
        return this._loading;
    }
    set loading(value) {
        this._loading = value;
        this.cd.markForCheck();
    }
    ngOnInit() {
        const loadingNotifications = this.loadingService
            .changes
            .pipe(filter(index => index === this.index));
        this.subscription = this.expandService
            .changes
            .pipe(filter(({ index }) => index === this.index), tap(({ expand }) => {
            if (!expand && this.loading) {
                this.loading = false;
            }
        }), filter(({ expand }) => expand), switchMap(x => of(x).pipe(delay(100), takeUntil(loadingNotifications))))
            .subscribe(() => this.loading = true);
        this.subscription.add(loadingNotifications.subscribe(() => this.loading = false));
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
};
__decorate([
    HostBinding("class.k-i-loading"),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], LoadingIndicatorDirective.prototype, "loading", null);
__decorate([
    Input("kendoTreeViewLoading"),
    __metadata("design:type", String)
], LoadingIndicatorDirective.prototype, "index", void 0);
LoadingIndicatorDirective = __decorate([
    Directive({ selector: '[kendoTreeViewLoading]' }),
    __metadata("design:paramtypes", [ExpandStateService,
        LoadingNotificationService,
        ChangeDetectorRef])
], LoadingIndicatorDirective);

/**
 * @hidden
 * Performs the right-to-left function composition. Functions must have a unary.
 */
const compose = (...args) => (data) => args.reduceRight((acc, curr) => curr(acc), data);

/**
 * @hidden
 */
class FlatEditingService {
    constructor(flatBinding) {
        this.flatBinding = flatBinding;
    }
    add({ sourceItem, destinationItem, dropPosition, sourceTree, destinationTree }) {
        // shallow clone the item as not to mistake it for its 'older' version when the remove handler kicks in to splice the item at its old position
        const clonedSourceDataItem = Object.assign({}, getDataItem(sourceItem));
        if (dropPosition === DropPosition.Over) {
            // expand the item that was dropped into
            expandDropTarget(destinationItem, destinationTree);
            const destinationItemId = getter(this.flatBinding.idField)(getDataItem(destinationItem));
            setter(this.flatBinding.parentIdField)(clonedSourceDataItem, destinationItemId);
            const lastChildNodeIndex = this.getLastVisibleChildNodeIndex(destinationTree, this.flatBinding.originalData, getDataItem(destinationItem));
            // insert after the last visible child
            const targetIndex = lastChildNodeIndex + 1;
            this.flatBinding.originalData.splice(targetIndex, 0, clonedSourceDataItem);
        }
        else {
            const shiftIndex = dropPosition === DropPosition.After ? 1 : 0;
            const targetIndex = this.flatBinding.originalData.indexOf(getDataItem(destinationItem)) + shiftIndex;
            this.flatBinding.originalData.splice(targetIndex, 0, clonedSourceDataItem);
            const destinationItemParentId = getter(this.flatBinding.parentIdField)(getDataItem(destinationItem));
            setter(this.flatBinding.parentIdField)(clonedSourceDataItem, destinationItemParentId);
        }
        if (sourceTree !== destinationTree) {
            this.addChildNodes(clonedSourceDataItem, sourceTree);
        }
        this.rebindData();
        // increment the parent page size => an item is moved into it
        const updatedParent = dropPosition === DropPosition.Over ? getDataItem(destinationItem) : getDataItem(destinationItem.parent);
        incrementPageSize(destinationTree, updatedParent);
        // the page sizes are stored by data-item reference => copy the old item ref page size to the new item reference
        copyPageSize(destinationTree, getDataItem(sourceItem), clonedSourceDataItem);
    }
    remove({ sourceItem, sourceTree, destinationTree }) {
        const sourceDataItem = getDataItem(sourceItem);
        const sourceItemIndex = this.flatBinding.originalData.indexOf(sourceDataItem);
        this.flatBinding.originalData.splice(sourceItemIndex, 1);
        if (sourceTree !== destinationTree) {
            this.removeChildNodes(sourceDataItem, sourceTree);
        }
        this.rebindData();
        // emit collapse for the parent node if its last child node was spliced
        const parentChildren = sourceItem.parent ? sourceItem.parent.children : [];
        collapseEmptyParent(sourceItem.parent, parentChildren, sourceTree);
        // decrement source item parent page size => an item has been removed from it
        decrementPageSize(sourceTree, getDataItem(sourceItem.parent));
    }
    addChildNodes(dataItem, source) {
        const itemChildren = this.fetchAllDescendantNodes(dataItem, source);
        this.flatBinding.originalData.push(...itemChildren);
    }
    removeChildNodes(dataItem, source) {
        const sourceChildren = this.fetchAllDescendantNodes(dataItem, source);
        sourceChildren.forEach(item => {
            const index = this.flatBinding.originalData.indexOf(item);
            this.flatBinding.originalData.splice(index, 1);
        });
    }
    fetchAllDescendantNodes(node, treeview) {
        let nodes = this.fetchChildNodes(node, treeview);
        nodes.forEach(node => nodes = nodes.concat(this.fetchAllDescendantNodes(node, treeview) || []));
        return nodes;
    }
    fetchChildNodes(node, treeview) {
        if (!node) {
            return [];
        }
        let nodes = [];
        treeview
            .children(node)
            .pipe(take(1))
            .subscribe(children => nodes = nodes.concat(children || []));
        return nodes;
    }
    getLastVisibleChildNodeIndex(treeview, data, node) {
        if (!isPresent(treeview.loadMoreService) || !treeview.hasChildren(node)) {
            return data.length;
        }
        const visibleNodesCount = treeview.loadMoreService.getGroupSize(node);
        const visibleChildren = this.fetchChildNodes(node, treeview).slice(0, visibleNodesCount);
        const lastNode = visibleChildren[visibleChildren.length - 1];
        const lastNodeIndex = data.indexOf(lastNode);
        return lastNodeIndex;
    }
    rebindData() {
        this.flatBinding.nodes = this.flatBinding.originalData;
    }
}

const findChildren = (prop, nodes, value) => nodes.filter((x) => prop(x) === value);
const mapToTree = (currentLevelNodes, allNodes, parentIdField, idField) => {
    if (!isArrayWithAtLeastOneItem(currentLevelNodes)) {
        return [];
    }
    return currentLevelNodes.map((node) => ({
        dataItem: node,
        children: mapToTree(findChildren(getter(parentIdField), allNodes || [], getter(idField)(node)), allNodes, parentIdField, idField)
    }));
};
/**
 * A directive which encapsulates the retrieval of the child nodes.
 */
let FlatDataBindingDirective = class FlatDataBindingDirective {
    constructor(treeView) {
        this.treeView = treeView;
        /**
         * @hidden
         */
        this.originalData = [];
        this.visibleNodes = new Set();
        this._filterSettings = defaultTreeviewFilterSettings;
        this.treeView.isVisible = (node) => this.visibleNodes.has(node);
    }
    /**
     * The nodes which will be displayed by the TreeView.
     */
    set nodes(values) {
        this.originalData = values || [];
        if (!isNullOrEmptyString(this.parentIdField)) {
            const prop = getter(this.parentIdField);
            this.treeView.nodes = this.originalData.filter(compose(isBlank, prop));
            this.filterData = mapToTree(this.treeView.nodes, this.originalData, this.parentIdField, this.idField);
            this.visibleNodes.clear();
            this.updateNodesVisibility(this.filterData);
        }
        else {
            this.treeView.nodes = this.originalData.slice(0);
        }
    }
    /**
     * @hidden
     * A callback which determines whether a TreeView node should be rendered as hidden.
     */
    set isVisible(fn) {
        this.treeView.isVisible = fn;
    }
    /**
     * Applies a filter and changes the visibility of the component's nodes accordingly.
     * To customize the built-in filtering, use the [filterSettings]({% slug api_treeview_flatdatabindingdirective %}#toc-filtersettings) prop.
     */
    set filter(term) {
        this.handleFilterChange(term);
    }
    /**
     * The settings which are applied when performing a filter on the component's data.
     */
    set filterSettings(value) {
        this._filterSettings = Object.assign({}, defaultTreeviewFilterSettings, value);
    }
    get filterSettings() {
        return this._filterSettings;
    }
    /**
     * @hidden
     */
    ngOnInit() {
        if (isPresent(this.parentIdField) && isPresent(this.idField)) {
            const fetchChildren = (node) => findChildren(getter(this.parentIdField), this.originalData || [], getter(this.idField)(node));
            this.treeView.hasChildren = (node) => fetchChildren(node).length > 0;
            this.treeView.children = (node) => of(fetchChildren(node));
            this.treeView.editService = new FlatEditingService(this);
            this.treeView.filterChange.subscribe(this.handleFilterChange.bind(this));
            if (this.treeView.filter) {
                this.handleFilterChange(this.treeView.filter);
            }
        }
    }
    /**
     * @hidden
     */
    ngOnChanges(changes) {
        if (isChanged("parentIdField", changes, false)) {
            this.nodes = this.originalData;
        }
    }
    /**
     * @hidden
     */
    handleFilterChange(term) {
        if (!this.filterData) {
            return;
        }
        const filteredData = term
            ? filterTree(this.filterData, term, this.filterSettings, this.treeView.textField)
            : this.filterData;
        this.visibleNodes.clear();
        this.updateNodesVisibility(filteredData);
    }
    updateNodesVisibility(items) {
        items.forEach((wrapper) => {
            this.visibleNodes.add(wrapper.dataItem);
            if (wrapper.children) {
                this.updateNodesVisibility(wrapper.children);
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], FlatDataBindingDirective.prototype, "nodes", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], FlatDataBindingDirective.prototype, "parentIdField", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FlatDataBindingDirective.prototype, "idField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], FlatDataBindingDirective.prototype, "isVisible", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], FlatDataBindingDirective.prototype, "filter", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], FlatDataBindingDirective.prototype, "filterSettings", null);
FlatDataBindingDirective = __decorate([
    Directive({ selector: "[kendoTreeViewFlatDataBinding]" }),
    __metadata("design:paramtypes", [DataBoundComponent])
], FlatDataBindingDirective);

const buildItem = (index, dataItem) => ({ dataItem, index });
let id = 0;
const TREE_ITEM_ROLE = 'treeitem';
const BUTTON_ROLE = 'button';
/**
 * @hidden
 *
 * A directive which manages the expanded state of the TreeView.
 */
let TreeViewItemDirective = class TreeViewItemDirective {
    constructor(element, expandService, navigationService, selectionService, lookupService, renderer, ib) {
        this.element = element;
        this.expandService = expandService;
        this.navigationService = navigationService;
        this.selectionService = selectionService;
        this.lookupService = lookupService;
        this.renderer = renderer;
        this.ib = ib;
        this.role = TREE_ITEM_ROLE;
        this.isDisabled = false;
        this.isVisible = true;
        this.ariaChecked = 'false';
        this.id = id++;
        this.isInitialized = false;
        this.subscriptions = [];
        this.subscribe();
    }
    set isChecked(checked) {
        if (checked === 'checked') {
            this.ariaChecked = 'true';
        }
        else if (checked === 'indeterminate') {
            this.ariaChecked = 'mixed';
        }
        else {
            this.ariaChecked = 'false';
        }
    }
    get isExpanded() {
        return this._isExpanded || false;
    }
    set isExpanded(isExpanded) {
        this._isExpanded = isExpanded;
    }
    get isSelected() {
        return this._isSelected || false;
    }
    set isSelected(isSelected) {
        this._isSelected = isSelected;
    }
    get isButton() {
        return this.role === BUTTON_ROLE;
    }
    get treeItem() {
        return buildItem(this.index, this.dataItem);
    }
    get parentTreeItem() {
        return this.parentDataItem ? buildItem(this.parentIndex, this.parentDataItem) : null;
    }
    ngOnInit() {
        this.lookupService.registerItem(this.treeItem, this.parentTreeItem);
        this.registerNavigationItem();
        this.isInitialized = true;
        this.setAttribute('role', this.role);
        this.setAriaAttributes();
        this.setDisabledClass();
        this.updateTabIndex();
    }
    ngOnChanges(changes) {
        const { index, isDisabled } = changes;
        if (anyChanged(['index', 'checkable', 'isChecked', 'expandable', 'isExpanded', 'selectable', 'isSelected'], changes)) {
            this.setAriaAttributes();
        }
        if (isDisabled) {
            this.setDisabledClass();
        }
        this.moveLookupItem(changes);
        this.moveNavigationItem(index);
        this.disableNavigationItem(isDisabled);
    }
    ngOnDestroy() {
        this.navigationService.unregisterItem(this.id, this.index);
        this.lookupService.unregisterItem(this.index, this.dataItem);
        this.subscriptions = this.subscriptions.reduce((list, callback) => (callback.unsubscribe(), list), []);
    }
    subscribe() {
        this.subscriptions = [
            this.navigationService.moves
                .subscribe(() => {
                this.updateTabIndex();
                this.focusItem();
            }),
            this.navigationService.expands
                .pipe(filter(({ index }) => index === this.index && !this.isDisabled))
                .subscribe(({ expand }) => this.expand(expand))
        ];
    }
    registerNavigationItem() {
        this.navigationService.registerItem(this.id, this.index, this.isDisabled, this.isButton, this.isVisible);
        this.activateItem();
    }
    activateItem() {
        if (this.isDisabled) {
            return;
        }
        const navigationService = this.navigationService;
        const selectionService = this.selectionService;
        const index = this.index;
        selectionService.setFirstSelected(index, this.isSelected);
        if (!navigationService.isActive(index) && selectionService.isFirstSelected(index)) {
            navigationService.activateIndex(index);
        }
    }
    expand(shouldExpand) {
        this.expandService[shouldExpand ? 'expand' : 'collapse'](this.index, this.dataItem);
    }
    isFocusable() {
        return !this.isDisabled && this.navigationService.isFocusable(this.index);
    }
    focusItem() {
        if (this.isInitialized && this.navigationService.isActive(this.index)) {
            this.element.nativeElement.focus();
        }
    }
    moveLookupItem(changes = {}) {
        const { dataItem, index, parentDataItem, parentIndex } = changes;
        if ((index && index.firstChange) || //skip first change
            (!dataItem && !index && !parentDataItem && !parentIndex)) {
            return;
        }
        const oldIndex = (index || {}).previousValue || this.index;
        this.lookupService.replaceItem(oldIndex, this.treeItem, this.parentTreeItem);
    }
    moveNavigationItem(indexChange = {}) {
        const { currentValue, firstChange, previousValue } = indexChange;
        if (!firstChange && isPresent(currentValue) && isPresent(previousValue)) {
            this.navigationService.unregisterItem(this.id, previousValue);
            this.navigationService.registerItem(this.id, currentValue, this.isDisabled, this.isButton);
        }
    }
    disableNavigationItem(disableChange) {
        if (!disableChange || disableChange.firstChange) {
            return;
        }
        const service = this.navigationService;
        if (this.isDisabled) {
            service.activateClosest(this.index); //activate before unregister the item
        }
        else {
            service.activateFocusable();
        }
        service.unregisterItem(this.id, this.index);
        service.registerItem(this.id, this.index, this.isDisabled, this.isButton);
    }
    setAriaAttributes() {
        this.setAttribute('aria-level', this.ib.level(this.index).toString());
        // don't render attributes when the component configuration doesn't allow the specified state
        this.setAttribute('aria-expanded', this.expandable ? this.isExpanded.toString() : null);
        this.setAttribute('aria-selected', this.selectable ? this.isSelected.toString() : null);
        this.setAttribute('aria-checked', this.checkable ? this.ariaChecked : null);
    }
    setDisabledClass() {
        this.setClass('k-state-disabled', this.isDisabled);
    }
    setClass(className, toggle) {
        const action = toggle ? 'addClass' : 'removeClass';
        this.renderer[action](this.element.nativeElement, className);
    }
    updateTabIndex() {
        this.setAttribute('tabIndex', this.isFocusable() ? '0' : '-1');
    }
    setAttribute(attr, value) {
        if (!isPresent(value)) {
            this.renderer.removeAttribute(this.element.nativeElement, attr);
            return;
        }
        this.renderer.setAttribute(this.element.nativeElement, attr, value);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], TreeViewItemDirective.prototype, "dataItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TreeViewItemDirective.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TreeViewItemDirective.prototype, "parentDataItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TreeViewItemDirective.prototype, "parentIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TreeViewItemDirective.prototype, "role", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewItemDirective.prototype, "checkable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewItemDirective.prototype, "selectable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewItemDirective.prototype, "expandable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], TreeViewItemDirective.prototype, "isChecked", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewItemDirective.prototype, "isDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewItemDirective.prototype, "isVisible", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], TreeViewItemDirective.prototype, "isExpanded", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], TreeViewItemDirective.prototype, "isSelected", null);
TreeViewItemDirective = __decorate([
    Directive({ selector: '[kendoTreeViewItem]' }),
    __metadata("design:paramtypes", [ElementRef,
        ExpandStateService,
        NavigationService,
        SelectionService,
        TreeViewLookupService,
        Renderer2,
        IndexBuilderService])
], TreeViewItemDirective);

/**
 * @hidden
 *
 * A directive which manages the expanded state of the TreeView.
 */
let TreeViewItemContentDirective = class TreeViewItemContentDirective {
    constructor(element, navigationService, selectionService, renderer) {
        this.element = element;
        this.navigationService = navigationService;
        this.selectionService = selectionService;
        this.renderer = renderer;
        this.initialSelection = false;
        this.isSelected = isSelected;
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.navigationService.moves
            .subscribe(this.updateFocusClass.bind(this)));
        this.subscriptions.add(this.navigationService.selects
            .pipe(filter((index) => index === this.index))
            .subscribe((index) => this.selectionService.select(index, this.dataItem)));
        this.subscriptions.add(this.selectionService.changes
            .subscribe(() => {
            this.updateSelectionClass(this.isSelected(this.dataItem, this.index));
        }));
    }
    ngOnChanges(changes) {
        if (changes.initialSelection) {
            this.updateSelectionClass(this.initialSelection);
        }
        if (changes.index) {
            this.updateFocusClass();
        }
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    updateFocusClass() {
        this.render(this.navigationService.isActive(this.index), 'k-state-focused');
    }
    updateSelectionClass(selected) {
        this.render(selected, 'k-state-selected');
    }
    render(addClass, className) {
        const action = addClass ? 'addClass' : 'removeClass';
        this.renderer[action](this.element.nativeElement, className);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], TreeViewItemContentDirective.prototype, "dataItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TreeViewItemContentDirective.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TreeViewItemContentDirective.prototype, "initialSelection", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], TreeViewItemContentDirective.prototype, "isSelected", void 0);
TreeViewItemContentDirective = __decorate([
    Directive({ selector: '[kendoTreeViewItemContent]' }),
    __metadata("design:paramtypes", [ElementRef,
        NavigationService,
        SelectionService,
        Renderer2])
], TreeViewItemContentDirective);

/**
 * @hidden
 *
 * Represents the CheckBox component of the Kendo UI TreeView for Angular.
 *
 */
let CheckBoxComponent = class CheckBoxComponent {
    constructor(element, renderer, changeDetector) {
        this.element = element;
        this.renderer = renderer;
        this.changeDetector = changeDetector;
        /**
         * Specifies the [`id`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id) of the component.
         */
        this.id = `_${guid()}`;
        /**
         * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabindex = 0;
        /**
         * Fires when the user changes the check state of the component.
         */
        this.checkStateChange = new EventEmitter();
        this.checkState = 'none';
    }
    //XXX: implement ComponentValueAccessor
    //XXX: focus/blur methods
    get classWrapper() { return true; }
    get indeterminate() {
        return this.checkState === 'indeterminate';
    }
    get checked() {
        return this.checkState === 'checked';
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.element.nativeElement, "tabindex");
    }
    ngDoCheck() {
        this.checkState = this.isChecked(this.node, this.index);
    }
    handleChange(e) {
        const state = e.target.checked ? 'checked' : 'none';
        // update the View State so that Angular updates the input if the isChecked value is the same
        this.checkState = state;
        this.changeDetector.detectChanges();
        this.checkStateChange.emit(state);
    }
};
__decorate([
    HostBinding('class.k-checkbox-wrapper'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CheckBoxComponent.prototype, "classWrapper", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], CheckBoxComponent.prototype, "id", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], CheckBoxComponent.prototype, "isChecked", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], CheckBoxComponent.prototype, "node", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], CheckBoxComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], CheckBoxComponent.prototype, "labelText", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], CheckBoxComponent.prototype, "tabindex", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], CheckBoxComponent.prototype, "checkStateChange", void 0);
CheckBoxComponent = __decorate([
    Component({
        selector: 'kendo-checkbox',
        template: `
        <input
            class="k-checkbox"
            type="checkbox"
            [id]="id"
            [checked]="checked"
            [indeterminate]="indeterminate"
            [tabindex]="tabindex"
            (change)="handleChange($event)"
        />
        <label
            class="k-checkbox-label"
            tabindex="-1"
            [for]="id"
        >{{labelText}}</label>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        ChangeDetectorRef])
], CheckBoxComponent);

const COMPONENT_DIRECTIVES = [
    CheckBoxComponent
];
/**
 * @hidden
 *
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }}) definition for the CheckBox component.
 */
let CheckBoxModule = class CheckBoxModule {
};
CheckBoxModule = __decorate([
    NgModule({
        declarations: [COMPONENT_DIRECTIVES],
        exports: [COMPONENT_DIRECTIVES]
    })
], CheckBoxModule);

/**
 * A directive which enables the update of the initially provided data array during drag-and-drop.
 *
 * Either use this directive in combination with one of the data binding directives ([`kendoTreeViewHierarchyBinding`]({% slug api_treeview_hierarchybindingdirective %})
 * or [`kendoTreeViewFlatDataBinding`]({% slug api_treeview_flatdatabindingdirective %})) which set their own edit handlers, or provide
 * your own [`editService`]({% slug api_treeview_editservice %}) to this directive. The latter subscribes to and calls the
 * [`addItem`]({% slug api_treeview_treeviewcomponent %}#toc-additem) and [`removeItem`]({% slug api_treeview_treeviewcomponent %}#toc-removeitem)
 * handlers when the corresponding events are triggered by the TreeView component.
 */
let DragAndDropEditingDirective = class DragAndDropEditingDirective {
    constructor(treeview) {
        this.treeview = treeview;
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.treeview.addItem.subscribe(this.handleAdd.bind(this)));
        this.subscriptions.add(this.treeview.removeItem.subscribe(this.handleRemove.bind(this)));
    }
    /**
     * Specifies the handlers called on drag-and-drop [`addItem`]({% slug api_treeview_treeviewcomponent %}#toc-additem)
     * and [`removeItem`]({% slug api_treeview_treeviewcomponent %}#toc-removeitem) events.
     */
    set editService(service) {
        this.treeview.editService = service;
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    handleAdd(args) {
        if (!isPresent(this.treeview.editService)) {
            throw new Error('No `editService` provided. Either provide your own implementation or use this directive in combination with one of the data binding directives (`kendoTreeViewHierarchyBinding` or `kendoTreeViewFlatDataBinding`).');
        }
        this.treeview.editService.add(args);
    }
    handleRemove(args) {
        if (!isPresent(this.treeview.editService)) {
            throw new Error('No `editService` provided. Either provide your own implementation or use this directive in combination with one of the data binding directives (`kendoTreeViewHierarchyBinding` or `kendoTreeViewFlatDataBinding`).');
        }
        this.treeview.editService.remove(args);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DragAndDropEditingDirective.prototype, "editService", null);
DragAndDropEditingDirective = __decorate([
    Directive({
        selector: '[kendoTreeViewDragAndDropEditing]'
    }),
    __metadata("design:paramtypes", [TreeViewComponent])
], DragAndDropEditingDirective);

const LOAD_MORE_DOC_LINK$1 = 'http://www.telerik.com/kendo-angular-ui/components/treeview/load-more-button/';
/**
 * A directive that enables the display of only a limited amount of nodes per level
 * ([see example]({% slug loadmorebutton_treeview %})).
 */
let LoadMoreDirective = class LoadMoreDirective {
    constructor(treeview) {
        this.treeview = treeview;
        /**
         * Keeps track of the current page size of each node over expand/collapse cycles.
         */
        this.pageSizes = new Map();
        /**
         * Used as an identifier for the root page size as the root collection of nodes is not associated with a data item.
         */
        this.rootLevelId = guid();
        this.treeview.loadMoreService = {
            getInitialPageSize: this.getInitalPageSize.bind(this),
            getGroupSize: this.getGroupSize.bind(this),
            setGroupSize: this.setGroupSize.bind(this),
            getTotalNodesCount: this.getTotalNodesCount.bind(this)
        };
    }
    /**
     * Specifies the callback that will be called when the load more button is clicked.
     * Providing a function is only required when additional nodes are fetched on demand
     * ([see example]({% slug loadmorebutton_treeview %}#toc-remote-data)).
     */
    set loadMoreNodes(loadMoreNodes) {
        if (typeof loadMoreNodes === 'string') {
            return;
        }
        this.treeview.loadMoreService.loadMoreNodes = loadMoreNodes;
    }
    ngOnChanges() {
        this.verifySettings();
    }
    verifySettings() {
        if (!isDevMode()) {
            return;
        }
        if (!isPresent(this.pageSize)) {
            throw new Error(`To use the TreeView \`kendoTreeViewLoadMore\` directive, you need to assign a \`pageSize\` value. See ${LOAD_MORE_DOC_LINK$1}.`);
        }
        const loadMoreNodes = this.treeview.loadMoreService.loadMoreNodes;
        if (isPresent(loadMoreNodes) && typeof loadMoreNodes !== 'function') {
            throw new Error(`The passed value to the \`kendoTreeViewLoadMore\` directive must be a function that retrieves additional nodes. See ${LOAD_MORE_DOC_LINK$1}.`);
        }
        if (isPresent(loadMoreNodes) && !isPresent(this.totalField)) {
            throw new Error(`When a function to fetch additional nodes is provided to the \`kendoTreeViewLoadMore\` directive, the \`totalField\` and \`totalRootNodes\` values must also be provided. See ${LOAD_MORE_DOC_LINK$1}.`);
        }
    }
    getGroupSize(dataItem) {
        const itemKey = dataItem || this.rootLevelId;
        return this.pageSizes.has(itemKey) ? this.pageSizes.get(itemKey) : this.pageSize;
    }
    setGroupSize(dataItem, pageSize) {
        const itemKey = dataItem || this.rootLevelId;
        const normalizedSizeValue = pageSize > 0 ? pageSize : 0;
        this.pageSizes.set(itemKey, normalizedSizeValue);
    }
    getTotalNodesCount(dataItem, loadedNodesCount) {
        if (isPresent(dataItem) && isPresent(this.totalField)) {
            return dataItem[this.totalField];
        }
        else if (!isPresent(dataItem) && isPresent(this.totalRootNodes)) {
            return this.totalRootNodes;
        }
        else {
            return loadedNodesCount;
        }
    }
    getInitalPageSize() {
        return this.pageSize;
    }
};
__decorate([
    Input('kendoTreeViewLoadMore'),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], LoadMoreDirective.prototype, "loadMoreNodes", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], LoadMoreDirective.prototype, "pageSize", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], LoadMoreDirective.prototype, "totalRootNodes", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LoadMoreDirective.prototype, "totalField", void 0);
LoadMoreDirective = __decorate([
    Directive({
        selector: '[kendoTreeViewLoadMore]'
    }),
    __metadata("design:paramtypes", [TreeViewComponent])
], LoadMoreDirective);

const COMPONENT_DIRECTIVES$1 = [
    TreeViewComponent,
    TreeViewGroupComponent,
    TreeViewItemDirective,
    TreeViewItemContentDirective,
    NodeTemplateDirective,
    CheckDirective,
    DisableDirective,
    ExpandDirective,
    SelectDirective,
    HierarchyBindingDirective,
    LoadingIndicatorDirective,
    FlatDataBindingDirective,
    DragAndDropDirective,
    DragClueTemplateDirective,
    DragClueComponent,
    DropHintTemplateDirective,
    DropHintComponent,
    DragAndDropEditingDirective,
    LoadMoreDirective,
    LoadMoreButtonTemplateDirective
];
/**
 * @hidden
 */
let SharedModule = class SharedModule {
};
SharedModule = __decorate([
    NgModule({
        declarations: [COMPONENT_DIRECTIVES$1],
        exports: [COMPONENT_DIRECTIVES$1],
        imports: [
            CommonModule,
            CheckBoxModule,
            InputsModule
        ],
        entryComponents: [
            DragClueComponent,
            DropHintComponent
        ]
    })
], SharedModule);

const EXPORTS = [
    TreeViewComponent,
    NodeTemplateDirective,
    CheckDirective,
    DisableDirective,
    ExpandDirective,
    SelectDirective,
    HierarchyBindingDirective,
    FlatDataBindingDirective,
    DragAndDropDirective,
    DragClueTemplateDirective,
    DropHintTemplateDirective,
    DragAndDropEditingDirective,
    LoadMoreDirective,
    LoadMoreButtonTemplateDirective
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }}) definition for the TreeView component.
 */
let TreeViewModule = class TreeViewModule {
};
TreeViewModule = __decorate([
    NgModule({
        exports: [EXPORTS],
        imports: [SharedModule]
    })
], TreeViewModule);

/**
 * Generated bundle index. Do not edit.
 */

export { CheckBoxComponent, CheckBoxModule, DataChangeNotificationService, DragClueComponent, DragClueService, DropHintComponent, DropHintService, DragAndDropAssetService, PreventableEvent, ExpandStateService, IndexBuilderService, LoadingIndicatorDirective, LoadingNotificationService, NavigationService, NodeChildrenService, SelectionService, SharedModule, TreeViewGroupComponent, TreeViewItemContentDirective, TreeViewItemDirective, TreeViewLookupService, TreeViewComponent, TreeViewModule, NodeTemplateDirective, CheckDirective, DisableDirective, ExpandDirective, SelectDirective, DataBoundComponent, ExpandableComponent, HierarchyBindingDirective, FlatDataBindingDirective, DragAndDropDirective, DragAndDropEditingDirective, DropHintTemplateDirective, DragClueTemplateDirective, DropAction, DropPosition, TreeItemDropEvent, TreeItemDragStartEvent, TreeItemDragEvent, LoadMoreDirective, LoadMoreButtonTemplateDirective };
